import React from "react";
import { Link } from "react-router-dom";

const AccessRestricted = () => {
  return (
    <main class="px-6 py-24 sm:py-32 lg:px-8">
      <div class="text-center">
        <p class="text-base font-semibold text-gold">403</p>
        <h1 class="mt-4 text-3xl font-bold tracking-tight text-white sm:text-5xl">
          Access Forbidden
        </h1>
        <p class="mt-6 text-base leading-7 text-gray-600">
          Sorry, your access to this resource has been denied.
        </p>
        <div class="mt-10 flex items-center justify-center gap-x-6">
          <Link
            to="/"
            class="rounded-md bg-gold px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-opacity-70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
          >
            Go back home
          </Link>
          {/* <a href="#" class="text-sm font-semibold text-gray-900">
            Contact support <span aria-hidden="true">&rarr;</span>
          </a> */}
        </div>
      </div>
    </main>
  );
};

export default AccessRestricted;
