import { useEffect, useState, useContext } from "react";
import { NumberAnimation, NumberAnimationNoDecimals } from "../../lib/utils";
import { formatStrictNumber, formatDenominator } from "../../lib/utils";
// import { getUserShareClaimEve  nts } from "../../hooks/FetchContractEvents";
import { useAccount } from "wagmi";
// import axios from "axios";
// import { formatEther } from "viem";
import { getStatistics } from "../../hooks/FetchContractEvents";
import Skeleton from "react-loading-skeleton";
import { DappContext } from "../../context/Dapp.context";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const statHeaders = ["User deposited", "User claims", "Community payouts"];

export default function Stats() {
  // const [loaded, setLoaded] = useState(false);
  // const [data, setData] = useState([]);

  // const [totalUserClaims, setTotalUserClaims] = useState(0);

  const { address } = useAccount();

  const { wrongNetwork, statData, setStatData, mounted, setMounted } =
    useContext(DappContext);

  useEffect(() => {
    const fetchStatistics = async () => {
      setMounted(false);
      const response = await getStatistics(address);
      setStatData(response);
      setMounted(true);
      // setLoaded(true);
    };

    if (!wrongNetwork && !mounted) {
      fetchStatistics();
    }
  }, [address, wrongNetwork, mounted]);

  // console.log(Number(data[0].stat));

  return (
    <main>
      <div className="relative isolate overflow-hidden">
        {/* Stats */}
        <div className="">
          <dl className="mx-auto grid  grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 lg:px-2 xl:px-0  border-b border-gray-900">
            {statHeaders.map((stat, statIdx) => (
              <div
                key={statIdx}
                className={classNames(
                  statIdx % 2 === 1
                    ? "sm:border-l border-b lg:border-b-0 border-gray-900 "
                    : statIdx === 2
                    ? "lg:border-l "
                    : " border-b lg:border-b-0 border-gray-900",
                  "flex items-baseline flex-wrap justify-between border-gray-900 gap-y-2 gap-x-4 px-4 py-10 sm:px-6 xl:px-8 "
                )}
              >
                {mounted ? (
                  <dt className="text-lg font-medium leading-6 text-darkgray">
                    {stat}
                  </dt>
                ) : (
                  <Skeleton height={20} width={150} />
                )}

                {mounted ? (
                  <dd
                    className={classNames(
                      stat.changeType === "negative"
                        ? "text-green-600"
                        : "text-green-700",
                      "text-lg font-medium"
                    )}
                  >
                    +{statData[statIdx].percentageIncrease}%
                  </dd>
                ) : (
                  <Skeleton height={20} width={50} />
                )}

                {mounted ? (
                  <dd className="w-full flex-none text-5xl font-medium text-light mt-4 line-clamp-2">
                    {statIdx === 0 ? (
                      <div className="flex items-center gap-4">
                        {mounted && (
                          <span className="flex">
                            <NumberAnimationNoDecimals
                              n={Number(
                                formatStrictNumber(Number(statData[0].stat))
                              )}
                            />
                            {formatDenominator(Number(statData[0].stat))}
                          </span>
                        )}
                        PNGVN
                      </div>
                    ) : statIdx === 1 ? (
                      <div className="flex items-center gap-4">
                        <NumberAnimation n={Number(statData[1].stat)} /> ETH
                      </div>
                    ) : (
                      <div className="flex items-center gap-4">
                        $US{" "}
                        <span className="flex">
                          <NumberAnimationNoDecimals
                            n={Number(
                              formatStrictNumber(Number(statData[2].stat))
                            )}
                          />
                          {formatDenominator(Number(statData[2].stat))}
                        </span>
                      </div>
                    )}
                  </dd>
                ) : (
                  <div className="mt-2">
                    <Skeleton height={60} width={250} />
                  </div>
                )}
              </div>
            ))}
          </dl>
        </div>

        <div
          className="absolute left-0 top-full -z-10 mt-96 origin-top-left translate-y-40 -rotate-90 transform-gpu opacity-20 blur-3xl sm:left-1/2 sm:-ml-96 sm:-mt-10 sm:translate-y-0 sm:rotate-0 sm:transform-gpu sm:opacity-50"
          aria-hidden="true"
        >
          <div
            className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#9E712C] to-[#855A17]"
            style={{
              clipPath:
                "polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)",
            }}
          />
        </div>
      </div>
    </main>
  );
}
