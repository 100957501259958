import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { ThemeProvider } from "@material-tailwind/react";

import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { WagmiConfig } from "wagmi";
import { mainnet, sepolia } from "viem/chains";
import { BrowserRouter } from "react-router-dom";

import "react-loading-skeleton/dist/skeleton.css";
import { SkeletonTheme } from "react-loading-skeleton";

import { DappProvider } from "./context/Dapp.context";

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = "0624ea8d2702a98871b0f50e261e23a3";

// 2. Create wagmiConfig
const metadata = {
  name: "PNGVN",
  description:
    "PNGVN Be the change. PNGVN token is the first token structured as a fund in the NFT and crypto space.  One token with the value of 130 of the top companies within.  Backed by a strong community you secure a bright future with the PNGVN token the world’s first dividend paying token that will pay it’s holders quarterly.  it’s time for a fresh start with PNGVN.",
  url: "https://app.pngvntoken.com/", // origin must match your domain & subdomain
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const chains = [mainnet, sepolia];
const wagmiConfig = defaultWagmiConfig({
  chains, // required
  projectId, // required
  metadata, // required
  themeVariables: {
    "--wcm-font-family": "Roboto, sans-serif",
    "--wcm-accent-color": "#F5841F",
  },
});

// 3. Create modal
createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  // themeMode: "dark",
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <WagmiConfig config={wagmiConfig}>
        <ThemeProvider>
          <SkeletonTheme baseColor="#313131" highlightColor="#525252">
            <DappProvider>
              <App />
            </DappProvider>
          </SkeletonTheme>
        </ThemeProvider>
      </WagmiConfig>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
