import React, { useState } from "react";
import { Button } from "@material-tailwind/react";
import {
  usePrepareContractWrite,
  useWaitForTransaction,
  useContractWrite,
} from "wagmi";
import { DepositoryContract } from "../../contracts/Depository";
import { toast } from "sonner";
import { formatEther, parseEther } from "viem";

const ConfigureSmartContract = ({
  root,
  ethSharePool,
  setActiveStep,
  shares,
  activeStep,
}) => {
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [prepareError, setPrepareError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  // console.log(ethSharePool);

  const { config } = usePrepareContractWrite({
    ...DepositoryContract,
    functionName: "setNewClaimRound",
    args: [root],
    value: ethSharePool.toString() ?? "0",
    onSuccess(data) {
      setPrepareError(false);
      setErrorMsg("");
    },
    onError(error) {
      setPrepareError(true);
      setErrorMsg(error.shortMessage);
    },
  });

  const { write: SetClaimRound, data: txnData } = useContractWrite({
    ...config,
    onSuccess(data) {
      setIsBtnLoading(true);
    },
    onError(error) {
      setIsBtnLoading(false);
      toast.error(error.shortMessage);
    },
  });

  useWaitForTransaction({
    hash: txnData?.hash,
    onSuccess(data) {
      setActiveStep(4);
      toast.success("Revenue share round successfully created");
      setIsBtnLoading(false);
    },
    onError(error) {
      //   NotifyError(error.message);
      //   setBtnLoading(false);
      setIsBtnLoading(false);
      toast.error(error.shortMessage);
    },
  });

  const handleClick = () => {
    if (prepareError) {
      toast.error(errorMsg);
      return;
    }

    setIsBtnLoading(true);
    SetClaimRound?.();
  };

  const goBackClickHandler = () => {
    setActiveStep(2);
  };

  return (
    <div>
      <div className="flex flex-col gap-2">
        <span className="text-light text-2xl font-semibold">
          Configure smart contract
        </span>
        <p className="text-darkgray font-normal">
          Set merkle root, fund revenue share pool and set claim status live
        </p>
      </div>

      <div className="">
        <div className="flex flex-col gap-4 my-20">
          <div className="flex justify-between items-center">
            <span className="text-light text-lg font-semibold">
              Merkle Root
            </span>
            <span className="text-light text-lg font-semibold line-clamp-1">
              {root}
            </span>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-light text-lg font-semibold">
              Revenue Share Pool
            </span>
            <span className="text-light text-lg font-semibold">
              {formatEther(ethSharePool).toString() ?? "N/A"} Eth
            </span>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-light text-lg font-semibold">
              Claim Status
            </span>
            <span className="text-light text-lg font-semibold">Live</span>
          </div>
        </div>

        {activeStep === 3 && (
          <div className="flex justify-between mt-10">
            <Button
              onClick={goBackClickHandler}
              className="bg-gold"
              disabled={isBtnLoading || activeStep === 4}
            >
              Back
            </Button>
            <Button
              onClick={handleClick}
              disabled={isBtnLoading || activeStep === 4}
              className="bg-gold"
            >
              {isBtnLoading
                ? "Writing to smart contract..."
                : "Write to smart contract"}
            </Button>
          </div>
        )}
      </div>

      {activeStep === 4 && (
        <div className="mt-10">
          <div className="flex flex-col gap-4 text-green-600">
            <span className=" text-4xl font-semibold">Success</span>
            <p className="text-xl ">
              {shares.length} holders can now claim a total of{" "}
              {formatEther(ethSharePool).toString() ?? "N/A"} Eth
            </p>
            <p className=" text-xl font-normal text-darkgray">
              You have successfully generated a new revenue share claim round.
              If this action was not what you intended, please navigate to the
              emergency settings tab immediately, pause the claim status and
              contact the development team for support
            </p>
          </div>

          {/* <div className="flex justify-center mt-14">
          <Button className="bg-green-600 text-white w-72">Home</Button>
        </div> */}
        </div>
      )}
    </div>
  );
};

export default ConfigureSmartContract;
