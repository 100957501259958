import PngvnTokenClaim from "./abis/TokenClaimAbi.json";

const PngvnTokenClaimAddress = "0xa218a4dfe038c9d357b49f58c69a53ac60d87dc0";
//  Todo - Final new mainnet that takes in old address and sets leaf to claimed on admin payment issue 0xa218a4dfe038c9d357b49f58c69a53ac60d87dc0
// Todo - add Final Mainnet Token: 0x556b8ee04704ddecd8c4515955af4f083eabd559

// 0xb1ea50C94f01762Cfa2caE1f454B13DCf6Be35Ea last testnet works fine

export const PngvnTokenClaimContract = {
  address: PngvnTokenClaimAddress,
  abi: PngvnTokenClaim,
};
