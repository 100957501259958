import { useRef, useState, useEffect } from "react";
import {
  // BuildingOffice2Icon,
  // PhoneIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/outline";
import { ReactComponent as XLogo } from "../assets/x.svg";
import { ReactComponent as Tg } from "../assets/tg.svg";
import { ReactComponent as Instagram } from "../assets/instagram.svg";
import { ReactComponent as Facebook } from "../assets/facebook.svg";
import emailjs from "@emailjs/browser";
import { toast } from "sonner";
import { useAccount } from "wagmi";

const Support = () => {
  const formRef = useRef();
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [allFieldsValid, setAllFieldsValid] = useState(false);

  const { address } = useAccount();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    if (form.firstName && form.lastName && form.email && form.message) {
      setAllFieldsValid(true);
    } else {
      setAllFieldsValid(false);
    }
  }, [form]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    emailjs
      .send(
        // "service_aasggxb", Rudeboy service
        "service_mtd9p78",
        // "template_nrut7h4", Rudeboy template Id
        "template_8ichr1g",
        {
          from_name: form.firstName + " " + form.lastName,
          from_wallet: address,
          to_name: "PNGVN",
          from_email: form.email,
          reply_to: form.email,
          message: form.message,
        },
        "alY93CpCFZv_n5p7E"
        // "D06HiQIpi_T45vkNP" Rubdeoy public key
      )
      .then(
        () => {
          setLoading(false);
          toast.success("Message sent successfully");
          setForm({
            firstName: "",
            lastName: "",
            email: "",
            message: "",
          });
        },
        (error) => {
          setLoading(false);

          console.log(error);
          alert("Something went wrong");
        }
      );
  };

  return (
    <div className="relative isolate ">
      <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
        <div className="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-32">
          <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
            <div className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden lg:w-1/2">
              <div
                className="absolute -left-56 top-[calc(100%-13rem)] transform-gpu blur-3xl lg:left-[max(-14rem,calc(100%-59rem))] lg:top-[calc(50%-7rem)]"
                aria-hidden="true"
              >
                <div
                  className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-br from-[#8982FF] to-[#4f46e5] opacity-20"
                  style={{
                    clipPath:
                      "polygon(74.1% 56.1%, 100% 38.6%, 97.5% 73.3%, 85.5% 100%, 80.7% 98.2%, 72.5% 67.7%, 60.2% 37.8%, 52.4% 32.2%, 47.5% 41.9%, 45.2% 65.8%, 27.5% 23.5%, 0.1% 35.4%, 17.9% 0.1%, 27.6% 23.5%, 76.1% 2.6%, 74.1% 56.1%)",
                  }}
                />
              </div>
            </div>
            <h2 className="text-3xl font-bold tracking-tight text-white">
              Our social media
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              PNGVN token is the first token structured as a fund in the NFT and
              crypto space. One token with the value of 130 of the top companies
              within. Backed by a strong community you secure a bright future
              with the PNGVN token the world’s first dividend paying token that
              will pay it’s holders quarterly. it’s time for a fresh start with
              PNGVN. Join our SM to stay informed.
            </p>
            <dl className="mt-10 space-y-4 text-base leading-7 text-gray-300">
              <div className="flex gap-x-4">
                <XLogo
                  className="h-5 w-5 text-gray-400 pt-0.5"
                  aria-hidden="true"
                />

                <a
                  className="hover:text-white"
                  target="_blank"
                  href="https://twitter.com/PNGVNToken"
                  rel="noreferrer"
                >
                  @PNGVNToken
                </a>
              </div>
              <div className="flex gap-x-4">
                <Tg className="h-6 w-6 text-gray-400" aria-hidden="true" />
                <a
                  className="hover:text-white "
                  target="_blank"
                  href="https://t.me/PNGVNToken"
                  rel="noreferrer"
                >
                  @PNGVNToken
                </a>
              </div>
              <div className="flex gap-x-4">
                <Instagram
                  className="h-6 w-6 text-gray-400"
                  aria-hidden="true"
                />
                <a
                  className="hover:text-white "
                  target="_blank"
                  href="https://www.instagram.com/PNGVNToken"
                  rel="noreferrer"
                >
                  @PNGVNToken
                </a>
              </div>
              <div className="flex gap-x-4">
                <Facebook
                  className="h-6 w-6 text-gray-400"
                  aria-hidden="true"
                />
                <a
                  className="hover:text-white "
                  target="_blank"
                  href="https://www.facebook.com/PNGVNToken
                  "
                  rel="noreferrer"
                >
                  @PNGVNToken
                </a>
              </div>
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Email</span>
                  <EnvelopeIcon
                    className="h-7 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                </dt>
                <dd>
                  <a
                    className="hover:text-white"
                    href="mailto:contact@pngvntoken.com"
                  >
                    contact@pngvntoken.com
                  </a>
                </dd>
              </div>
            </dl>
          </div>
        </div>

        {/* FORM STARTS HERE */}
        <form
          ref={formRef}
          onSubmit={handleSubmit}
          // action="#"
          // method="POST"
          className="px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-32 "
        >
          <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
            <h2 className="text-3xl font-bold tracking-tight text-white mb-6">
              Send us a message
            </h2>
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
              <div>
                <label
                  htmlFor="first-name"
                  className="block text-sm font-semibold leading-6 text-white"
                >
                  First name
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    name="firstName"
                    id="first-name"
                    autoComplete="given-name"
                    value={form.firstName}
                    onChange={handleChange}
                    className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="last-name"
                  className="block text-sm font-semibold leading-6 text-white"
                >
                  Last name
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    name="lastName"
                    id="last-name"
                    autoComplete="family-name"
                    value={form.lastName}
                    onChange={handleChange}
                    className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="email"
                  className="block text-sm font-semibold leading-6 text-white"
                >
                  Email
                </label>
                <div className="mt-2.5">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="email"
                    value={form.email}
                    onChange={handleChange}
                    className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              {/* <div className="sm:col-span-2">
                <label
                  htmlFor="phone-number"
                  className="block text-sm font-semibold leading-6 text-white"
                >
                  Phone number
                </label>
                <div className="mt-2.5">
                  <input
                    type="tel"
                    name="phone-number"
                    id="phone-number"
                    autoComplete="tel"
                    className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                </div>
              </div> */}
              <div className="sm:col-span-2">
                <label
                  htmlFor="message"
                  className="block text-sm font-semibold leading-6 text-white"
                >
                  Message
                </label>
                <div className="mt-2.5">
                  <textarea
                    name="message"
                    id="message"
                    rows={7}
                    value={form.message}
                    onChange={handleChange}
                    className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    defaultValue={""}
                  />
                </div>
              </div>
            </div>
            <div className="mt-8 flex justify-end">
              <button
                disabled={loading || !allFieldsValid}
                type="submit"
                className={`rounded-md bg-indigo-500 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 ${
                  allFieldsValid
                    ? "cursor-pointer hover:bg-indigo-400"
                    : "cursor-not-allowed opacity-70"
                }`}
              >
                {loading ? "Sending..." : "Send message"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default Support;
