import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <main class="px-6 py-24 sm:py-44 lg:px-8">
      <div class="text-center  fixed top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2">
        <div className="md:ml-[3.5rem] lg:ml-[10.5rem] w-full">
          <p class="text-base font-semibold text-gold">404</p>
          <h1 class="mt-4 text-3xl font-bold tracking-tight text-white sm:text-5xl">
            Page not found
          </h1>
          <p class="mt-6 text-base leading-7 text-gray-500">
            Sorry, we couldn’t find the page you’re looking for.
          </p>
          <div class="mt-10 flex items-center justify-center gap-x-6">
            <Link
              to="/"
              class="rounded-md bg-gold px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-opacity-70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
            >
              Go back home
            </Link>
            {/* <Link to="/support" class="text-sm font-semibold text-white">
              Contact support <span aria-hidden="true">&rarr;</span>
            </Link> */}
          </div>
        </div>
      </div>
    </main>
  );
};

export default NotFound;
