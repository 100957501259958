import { ethers } from "ethers";
import { DepositoryContract } from "../contracts/Depository";
import { formatEther, parseEther } from "viem";
import axios from "axios";

export const getUserDepositoryEvents = async (queryAddress) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();

  const depositoryContract = new ethers.Contract(
    DepositoryContract.address,
    DepositoryContract.abi,
    signer
  );

  const depositFilter = depositoryContract.filters.Deposited();

  const WithdrawFilter = depositoryContract.filters.Withdrew();

  try {
    const depositEvents = await depositoryContract.queryFilter(depositFilter);
    // console.log(depositEvents);

    const withdrawEvents = await depositoryContract.queryFilter(WithdrawFilter);
    // console.log(withdrawEvents);

    const filteredDepositEvents = depositEvents.filter(
      (event) => event.args.holder === queryAddress
    );

    const filteredWithdrawEvents = withdrawEvents.filter(
      (event) => event.args.holder === queryAddress
    );

    const mergedEvents = filteredDepositEvents.concat(filteredWithdrawEvents);

    mergedEvents.sort((a, b) => b.blockNumber - a.blockNumber);

    return mergedEvents;
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getUserShareClaimEvents = async (queryAddress) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();

  const depositoryContract = new ethers.Contract(
    DepositoryContract.address,
    DepositoryContract.abi,
    signer
  );

  const depositFilter = depositoryContract.filters.HolderShareClaimed();

  try {
    const claimEvents = await depositoryContract.queryFilter(depositFilter);
    // console.log(depositEvents);

    const filteredClaimEvents = claimEvents.filter(
      (event) => event.args.holder === queryAddress
    );

    filteredClaimEvents.sort((a, b) => b.blockNumber - a.blockNumber);

    return filteredClaimEvents;
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getDepositors = async (ethRatePerToken) => {
  // * FETCHING DEPOSITORS
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();

  const depositoryContract = new ethers.Contract(
    DepositoryContract.address,
    DepositoryContract.abi,
    signer
  );

  const depositorsFilter = depositoryContract.filters.Deposited();

  try {
    const depositEvents = await depositoryContract.queryFilter(
      depositorsFilter
    );

    // Create a Set to store unique depositor addresses
    const uniqueDepositorAddresses = new Set();

    // Iterate through the deposit events and add unique addresses to the Set
    depositEvents.forEach((event) => {
      uniqueDepositorAddresses.add(event.args.holder);
    });

    // console.log("check", Array.from(uniqueDepositorAddresses)); THIS HERE IS GOOD RETURNS ARRAY OF UNIQUE DEPOSITORS

    // * FETCHING BALANCES AND CREATING OBJECT FOR EACH DEPOSITOR

    // Old method of calculating total share pool in proportion to total deposits per holder
    // const totalDepositPool = await depositoryContract.totalDeposits();
    // const balancePromises = Array.from(uniqueDepositorAddresses).map(
    //   async (address) => {
    //     const balance = await depositoryContract.balances(address);

    //     // Calculate the share of revenue for each depositor
    //     const shareOfRevenue = ethers.BigNumber.from(balance)
    //       .mul(totalSharePool)
    //       .div(totalDepositPool);

    //     return {
    //       id: address,
    //       gngAmount: balance.toString(),
    //       share: shareOfRevenue.toString(),
    //     };
    //   }
    // );

    let totalSharePool = ethers.BigNumber.from(0);

    // Create an array to store promises for each balance query
    const balancePromises = Array.from(uniqueDepositorAddresses).map(
      async (address) => {
        const balance = await depositoryContract.balances(address);

        const oneEther = parseEther("1");

        // Calculate the share of revenue for each depositor
        const shareOfRevenue = ethers.BigNumber.from(balance)
          .mul(ethRatePerToken)
          .div(oneEther);

        totalSharePool = totalSharePool.add(shareOfRevenue);

        // console.log(totalSharePool.toString());

        return {
          id: address,
          gngAmount: balance.toString(),
          share: shareOfRevenue.toString(),
        };
      }
    );

    // Wait for all balance queries to complete
    const initialDepositorObjects = await Promise.all(balancePromises);

    // console.log("DEPOSITOORS", depositorObjects);

    const filteredDepositorObjects = initialDepositorObjects.filter(
      (depositor) => Number(formatEther(depositor.gngAmount)) > 0
    );

    // console.log("DEPOSITOORS FILTERED", filteredDepositorObjects);

    const depositorObjects = filteredDepositorObjects.sort(
      (a, b) => b.share - a.share
    );

    return { depositorObjects, totalSharePool };
  } catch (e) {
    console.log(e);
    console.log("Error fetching depositors");
    return [];
  }
};

export const getStatistics = async (queryAddress) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();

  const depositoryContract = new ethers.Contract(
    DepositoryContract.address,
    DepositoryContract.abi,
    signer
  );

  const depositShareClaimFilter =
    depositoryContract.filters.HolderShareClaimed();

  const depositFilter = depositoryContract.filters.Deposited();

  let userDepositedPercentageIncreasae = 0;
  let userTotalClaimedPercentageIncreasae = 0;
  let totalPayoutsPercentageIncreasae = 0;

  try {
    // * FETCHING TOTAL DEPOSITED BY USER
    const totalDeposited = await depositoryContract.balances(queryAddress);

    const totalDepositedFormatted = Number(formatEther(totalDeposited)).toFixed(
      0
    );

    const depositEvents = await depositoryContract.queryFilter(depositFilter);

    const filteredDepositEvents = depositEvents.filter(
      (event) => event.args.holder === queryAddress
    );

    if (filteredDepositEvents.length > 1) {
      const lastDeposit =
        filteredDepositEvents[filteredDepositEvents.length - 1].args.amount;

      const lastDepositFormatted = Number(formatEther(lastDeposit)).toFixed(0);

      const totalBefore = totalDepositedFormatted - lastDepositFormatted;

      userDepositedPercentageIncreasae = Number(
        (lastDepositFormatted / totalBefore) * 100
      ).toFixed(2);

      // console.log(lastDepositFormatted);
    } else if (filteredDepositEvents.length === 1) {
      userDepositedPercentageIncreasae = 100;
    }

    // * FETCHING TOTAL CLAIMED BY USER
    const userClaims = await getUserShareClaimEvents(queryAddress);

    const totalClaimed = userClaims.reduce(
      (total, item) => total + parseFloat(item.args.amount),
      0
    );

    const totalClaimedFormatted = Number(formatEther(totalClaimed)).toFixed(2);

    if (userClaims.length > 1) {
      const lastClaim = userClaims[0].args.amount;

      const lastClaimFormatted = Number(formatEther(lastClaim)).toFixed(2);

      const totalBefore = totalClaimedFormatted - lastClaimFormatted;

      userTotalClaimedPercentageIncreasae = Number(
        (lastClaimFormatted / totalBefore) * 100
      ).toFixed(2);
    } else if (userClaims.length === 1) {
      userTotalClaimedPercentageIncreasae = 100;
    }

    // * FETCHING TOTAL COMMUNITY PAYOUTS IN $USD
    const claimEvents = await depositoryContract.queryFilter(
      depositShareClaimFilter
    );

    const totalPayouts = claimEvents.reduce(
      (total, item) => total + parseFloat(item.args.amount),
      0
    );

    const totalPayoutsFormatted = Number(formatEther(totalPayouts)).toFixed(2);

    if (claimEvents.length > 1) {
      const lastPayout = claimEvents[claimEvents.length - 1].args.amount;

      const lastPayoutFormatted = Number(formatEther(lastPayout)).toFixed(2);

      const totalBefore = totalPayoutsFormatted - lastPayoutFormatted;

      totalPayoutsPercentageIncreasae = Number(
        (lastPayoutFormatted / totalBefore) * 100
      ).toFixed(2);
    } else if (claimEvents.length === 1) {
      userTotalClaimedPercentageIncreasae = 100;
    }

    // TODO  - Checkout to potentially replace this SUS ass API returning unexpected and unpredictable errors
    // TODO - add etherscan api key to .env file
    // const ethereumPriceResponse = await axios.get(
    //   "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=ETH,USD"
    // );
    // const ethUsdPrice = ethereumPriceResponse.data.USD; //cryptocompare

    const ethereumPriceResponse = await axios.get(
      "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd"
    );
    const ethUsdPrice = ethereumPriceResponse.data.ethereum.usd; // coingecko

    const totalPayoutsUsd = totalPayoutsFormatted * ethUsdPrice;

    return [
      {
        stat: totalDepositedFormatted,
        percentageIncrease: userDepositedPercentageIncreasae,
      },
      {
        stat: totalClaimedFormatted,
        percentageIncrease: userTotalClaimedPercentageIncreasae,
      },
      {
        stat: totalPayoutsUsd.toFixed(0),
        percentageIncrease: totalPayoutsPercentageIncreasae,
      },
    ];
  } catch (e) {
    console.log(e);
    console.log("Error fetching statistics");
    return [
      {
        stat: 0,
        percentageIncrease: 0,
      },
      {
        stat: 0,
        percentageIncrease: 0,
      },
      {
        stat: 0,
        percentageIncrease: 0,
      },
    ];
  }
};

export const getClaimRounds = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();

  const depositoryContract = new ethers.Contract(
    DepositoryContract.address,
    DepositoryContract.abi,
    signer
  );

  const dividendRoundFilter = depositoryContract.filters.DividendRoundCreated();

  try {
    const dividendRoundEvents = await depositoryContract.queryFilter(
      dividendRoundFilter
    );

    return dividendRoundEvents;
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getDividendRoundReport = async (dividendRoundId, pool) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();

  const depositoryContract = new ethers.Contract(
    DepositoryContract.address,
    DepositoryContract.abi,
    signer
  );

  const shareClaimFilter = depositoryContract.filters.HolderShareClaimed();

  try {
    const allShareClaims = await depositoryContract.queryFilter(
      shareClaimFilter
    );

    const filteredClaimEvents = allShareClaims.filter(
      (event) => event.args.roundId.toString() === dividendRoundId
    );

    const activeRound = await depositoryContract.activeRoundId();

    const isRoundActive = activeRound.toString() === dividendRoundId;

    const totalClaimed = filteredClaimEvents.reduce(
      (total, item) => total + parseFloat(item.args.amount),
      0
    );

    const totalClaimedFormatted = Number(formatEther(totalClaimed)).toFixed(2);

    const totalUnclaimed = pool - Number(formatEther(totalClaimed));

    // console.log(filteredClaimEvents);

    return {
      claims: filteredClaimEvents,
      status: isRoundActive,
      claimed: totalClaimedFormatted,
      unclaimed: totalUnclaimed.toFixed(2),
    };
  } catch (e) {
    console.log(e);
    return [];
  }
};
