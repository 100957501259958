import React from "react";
import TransactionCard from "./TransactionCard";
import { formatEther } from "viem";
import Skeleton from "react-loading-skeleton";

const TransactionTable = ({ title, activity, loaded }) => {
  // console.log(activity);
  return (
    <div>
      <div className="bg-[#1A1A21] h-[45px] border-y-[0.25px] border-gray-900 flex items-center">
        <span className="font-medium text-grey pl-4 md:pl-14">{title}</span>
      </div>

      {!loaded ? (
        <div className="px-14 py-10 space-y-10">
          <div className="flex justify-between">
            <div className="flex items-center gap-4">
              <Skeleton height={50} width={50} borderRadius={"9999px"} />
              <Skeleton height={15} width={200} count={2} className="mt-2" />
            </div>

            <div className="hidden sm:block">
              <Skeleton height={15} width={200} count={2} className="mt-2" />
            </div>

            <div className="hidden skl:block">
              <Skeleton height={15} width={200} count={2} className="mt-2" />
            </div>
          </div>

          <div className="flex justify-between">
            <div className="flex items-center gap-4">
              <Skeleton height={50} width={50} borderRadius={"9999px"} />
              <Skeleton height={15} width={200} count={2} className="mt-2" />
            </div>

            <div className="hidden sm:block">
              <Skeleton height={15} width={200} count={2} className="mt-2" />
            </div>

            <div className="hidden skl:block">
              <Skeleton height={15} width={200} count={2} className="mt-2" />
            </div>
          </div>

          <div className="flex justify-between">
            <div className="flex items-center gap-4">
              <Skeleton height={50} width={50} borderRadius={"9999px"} />
              <Skeleton height={15} width={200} count={2} className="mt-2" />
            </div>

            <div className="hidden sm:block">
              <Skeleton height={15} width={200} count={2} className="mt-2" />
            </div>

            <div className="hidden skl:block">
              <Skeleton height={15} width={200} count={2} className="mt-2" />
            </div>
          </div>
        </div>
      ) : activity.length > 0 ? (
        <div className={activity.length < 3 ? "h-[300px]" : ""}>
          {activity.map((item) => (
            <TransactionCard
              key={item.logIndex}
              event={item.event}
              // amount={formatEther(item.args[1]).toString()} this worked but showed long number
              amount={Number(formatEther(item.args.amount))
                .toFixed(2)
                .toString()}
              timestamp={item.args.timestamp}
              hash={item.transactionHash}
              isLastElement={activity[activity.length - 1] === item}
            />
          ))}
        </div>
      ) : (
        <div className="pb-32 pt-10 px-14 flex justify-center">
          <span className="text-grey ">No activity to show</span>
        </div>
      )}
    </div>
  );
};

export default TransactionTable;
