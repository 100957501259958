import React, { useState, useEffect } from "react";
import { Button } from "@material-tailwind/react";
import {
  //   useBalance,
  //   useAccount,
  //   useContractRead,
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  useAccount,
} from "wagmi";
import { DepositoryContract } from "../contracts/Depository";
// import { ethers } from "ethers";
import { toast } from "sonner";
import { parseEther } from "viem";
import { getUserDepositoryEvents } from "../hooks/FetchContractEvents";

const WithdrawBtn = ({
  withdrawAmount,
  refetch,
  setWithdrawAmount,
  fieldError,
  setStakingActivity,
}) => {
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [withdrawError, setWithdrawError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const { address } = useAccount();

  const { config } = usePrepareContractWrite({
    ...DepositoryContract,
    functionName: "withdraw",
    args: [parseEther(withdrawAmount || "0")],
    onSuccess(data) {
      setWithdrawError(false);
      setErrorMsg("");
    },
    onError(error) {
      setWithdrawError(true);
      setErrorMsg(error.cause.reason);
    },
  });

  const { write: Withdraw, data: withdrawalData } = useContractWrite({
    ...config,
    onSuccess(data) {
      setBtnLoading(true);
    },
    onError(error) {
      setBtnLoading(false);
      toast.error(error.shortMessage);
    },
  });

  const { isSuccess } = useWaitForTransaction({
    hash: withdrawalData?.hash,
    // onSuccess(data) {
    //   toast.success("Withdrawal successful");
    //   setBtnLoading(false);
    //   setWithdrawAmount("");
    //   refetch();
    // },
    onError(error) {
      toast.error(error.shortMessage);
      setBtnLoading(false);
    },
  });

  useEffect(() => {
    const handleSuccess = async () => {
      if (isSuccess) {
        const res = await getUserDepositoryEvents(address);
        setStakingActivity(res);

        setBtnLoading(false);
        setWithdrawAmount("");
        refetch();
        toast.success("Withdrawal successful");
      }
    };
    handleSuccess();
  }, [isSuccess]);

  const handleWithdrawalClick = () => {
    setBtnLoading(true);
    Withdraw?.();
  };
  return (
    <Button
      onClick={handleWithdrawalClick}
      className="bg-gold my-6 text-lg py-2"
      fullWidth
      disabled={isBtnLoading || !withdrawAmount || fieldError}
    >
      {isBtnLoading ? "WITHDRAWING..." : "WITHDRAW"}
    </Button>
  );
};

export default WithdrawBtn;
