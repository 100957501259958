import React, { useState } from "react";
import { getDepositors } from "../../hooks/FetchContractEvents";
import SetNewClaimSteps from "./SetNewClaimSteps";
// import { blockInvalidCharWithDecimal } from "../../lib/utils";
import GenerateShares from "./GenerateShares";
import ReviewAndConfirm from "./ReviewAndConfirm";
import ConfigureSmartContract from "./ConfigureSmartContract";
import { Button } from "@material-tailwind/react";

const RevenueShareSettings = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [ethPerTokenRate, setEthPerTokenRate] = useState("");
  const [root, setRoot] = useState("");
  const [shares, setShares] = useState([]);
  // const [ethSharePool, setEthSharePool] = useState("");

  // const handleDbWrite = async () => {

  //   const data = await getDepositors("15000000000000000000");
  //   console.log(data);
  // };

  //   console.log("madarchod root", root);

  return (
    <div className="">
      <div className="flex flex-col gap-4 pt-24">
        <h1 className="text-gold text-4xl font-semibold">
          Revenue Share Settings
        </h1>
        <p className="text-darkgray font-normal">
          This page is dedicated to setting up the revenue share for the
          platform. The process is broken down into 4 steps. Please follow the
          steps carefully to ensure the revenue share is set up correctly.
        </p>
      </div>
      <SetNewClaimSteps activeStep={activeStep} setActiveStep={setActiveStep} />

      <div className="bg-secondary rounded-xl w-full my-20 px-10 pt-10 pb-6">
        {activeStep === 1 ? (
          <GenerateShares
            setActiveStep={setActiveStep}
            setShares={setShares}
            // ethSharePool={ethSharePool}
            // setEthSharePool={setEthSharePool}
            //
            ethPerTokenRate={ethPerTokenRate}
            setEthPerTokenRate={setEthPerTokenRate}
          />
        ) : activeStep === 2 ? (
          <ReviewAndConfirm
            shares={shares.depositorObjects}
            ethSharePool={shares.totalSharePool}
            setActiveStep={setActiveStep}
            setShares={setShares}
            setEthPerTokenRate={setEthPerTokenRate}
            setRoot={setRoot}
          />
        ) : (
          <ConfigureSmartContract
            root={root}
            ethSharePool={shares.totalSharePool}
            setActiveStep={setActiveStep}
            activeStep={activeStep}
            shares={shares.depositorObjects}
          />
        )}
      </div>

      {/* <div>
        <Button onClick={handleDbWrite} color="blue" className="mt-24">
          Db Write
        </Button>

        <Button
          onClick={() => setActiveStep(activeStep + 1)}
          color="blue"
          className="mt-24 ml-10"
        >
          Step
        </Button>
        <Button
          onClick={() => setActiveStep(1)}
          color="blue"
          className="mt-24 ml-10"
        >
          Reset
        </Button>
      </div> */}
      <div className="w-full h-[1px]" />
    </div>
  );
};

export default RevenueShareSettings;
