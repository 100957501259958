import React, { useState } from "react";
import mmlogo from "../assets/metamask.svg";
import wclogo from "../assets/walletconnect.svg";
import { useConnect } from "wagmi";

// import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { InjectedConnector } from "wagmi/connectors/injected";
import { mainnet, sepolia } from "viem/chains";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { motion } from "framer-motion";
import ArrowSvg from "../assets/arrow.svg";

import LogoWhite from "../assets/LogoWhite.png";
import Logo from "../assets/PngvnLogoDesktop.png";

const arrowAni = {
  offScreen: {
    x: -10,
    opacity: 0,
  },
  onScreen: {
    x: 0,
    opacity: 1, // Changed opacity to 1
    transition: {
      ease: "easeInOut",
      duration: 0.3,
    },
  },
};

const SignIn = () => {
  const [isShown, setIsShown] = useState(0);
  const { open } = useWeb3Modal();
  const { connect } = useConnect();

  // const connector = new MetaMaskConnector({
  //   chains: [sepolia, mainnet],
  // });

  const connector = new InjectedConnector({
    chains: [sepolia, mainnet],
  });

  const handleMetamaskConnect = () => connect({ connector });

  // const handleMetamaskConnect = async () => {
  //   try {
  //     await connectAsync({
  //       chainId: mainnet.id,
  //       connector: InjectedConnector(),
  //     });
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const handleOtherWalletConnect = () => open();

  return (
    <div className="bg-black h-screen px-6  py-10">
      <img src={Logo} alt="gng" className="w-[150px] md:ml-10" />

      <div className=" sm:w-[400px] bg-[#19191a] rounded-xl text-white px-8 pt-[2.375rem] pb-[3rem] relative left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
        <div className="flex flex-col gap-1">
          <h1 className="text-[1.25rem] inter-semibold leading-6">Sign in</h1>
          <span className="text-white opacity-65 inter-normal">
            to continue to PNGVN app
          </span>
        </div>

        <div className="flex flex-col gap-3 mt-[2rem]">
          <button
            onMouseEnter={() => setIsShown(1)}
            onMouseLeave={() => setIsShown(0)}
            onClick={handleMetamaskConnect}
            className="connectSignInBtn bg-gray-500 bg-opacity-0 hover:bg-opacity-5 relative"
          >
            <img src={mmlogo} alt="metamask" className="w-[1.25rem]" />
            Continue with MetaMask
            <div className="w-4 absolute top-1/2 -translate-y-1/2 right-6 text-xl">
              <motion.img
                variants={arrowAni}
                initial="offScreen"
                animate={isShown === 1 ? "onScreen" : "offScreen"}
                src={ArrowSvg}
                alt="arrow"
                className="max-sm:hidden"
              />
            </div>
          </button>

          <button
            onMouseEnter={() => setIsShown(2)}
            onMouseLeave={() => setIsShown(0)}
            onClick={handleOtherWalletConnect}
            className="connectSignInBtn bg-gray-500 bg-opacity-0 hover:bg-opacity-5 relative"
          >
            <img src={wclogo} alt="metamask" className="w-[1.25rem]" />
            Continue with other wallets
            <div className="w-4 absolute top-1/2 -translate-y-1/2 right-6 text-xl">
              <motion.img
                variants={arrowAni}
                initial="offScreen"
                animate={isShown === 2 ? "onScreen" : "offScreen"}
                src={ArrowSvg}
                alt="arrow"
                className="max-sm:hidden"
              />
            </div>
          </button>
        </div>

        <div className="text-white opacity-65 inter-normal pt-8 text-sm">
          Don't have a wallet?
          <a
            target="_blank"
            rel="noreferrer"
            href="https://metamask.io/download/"
            className="inter-semibold pl-1 text-gold hover:text-[#FFD700] cursor-pointer"
          >
            Create one now
          </a>
        </div>

        <div className="h-[30px] w-[110px] bg-gold absolute top-[-30px] right-4 md:top-20 md:left-[-70px] md:rotate-[270deg] flex justify-center items-center gap-1 text-xs rounded-t-md">
          <img src={LogoWhite} alt="logo" className="h-3 w-4" />
          <p>PNGVN APP</p>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
