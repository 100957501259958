import React, { useState } from "react";
import {
  //   useBalance,
  //   useAccount,
  //   useContractRead,
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
} from "wagmi";
import { GngTokenContract } from "../contracts/GngToken";
import { DepositoryContract } from "../contracts/Depository";
// import { ethers } from "ethers";
import { Button } from "@material-tailwind/react";
import { maxUint256 } from "viem";

const ApproveBtn = ({ setApproved }) => {
  const [isBtnLoading, setBtnLoading] = useState(false);

  // console.log(parseEther("1000000000"));

  const { config } = usePrepareContractWrite({
    ...GngTokenContract,
    functionName: "approve",
    args: [DepositoryContract.address, maxUint256],
    onSuccess(data) {
      //   setFieldError(false);
      //   setErrorMsg("");
    },
    onError(error) {
      // setFieldError(true);
      // setErrorMsg(error.cause.reason);
      // console.log(error.cause.reason);
      // console.log(error);
      setBtnLoading(false);
    },
  });

  const { write: Approve, data: approvalData } = useContractWrite({
    ...config,
    onSuccess(data) {
      setBtnLoading(true);
    },
    onError(data) {
      //   setErrorMsg(data.message);
      //   NotifyError(data.message);
      //   setBtnLoading(false);
      setBtnLoading(false);
    },
  });

  useWaitForTransaction({
    hash: approvalData?.hash,
    onSuccess(data) {
      //
      setBtnLoading(false);
      setApproved(true);
    },
    onError(error) {
      //   NotifyError(error.message);
      //   setBtnLoading(false);
      setBtnLoading(false);
    },
  });

  const handleApproveClick = () => {
    setBtnLoading(true);
    Approve?.();
  };

  return (
    <Button
      onClick={handleApproveClick}
      disabled={isBtnLoading}
      className="bg-gold my-6 text-lg py-2"
      fullWidth
    >
      {isBtnLoading ? "APPROVING..." : "APPROVE"}
    </Button>
  );
};

export default ApproveBtn;
