import React, { useEffect, useState } from "react";
import {
  //   useBalance,
  //   useContractRead,
  useAccount,
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  // useContractEvent,
} from "wagmi";
// import { GngTokenContract } from "../contracts/GngToken";
import { DepositoryContract } from "../contracts/Depository";
// import { ethers } from "ethers";
import { Button } from "@material-tailwind/react";
import { toast } from "sonner";
import { parseEther } from "viem";
import { getUserDepositoryEvents } from "../hooks/FetchContractEvents";

const DepositBtn = ({
  stakeAmount,
  fieldError,
  setStakeAmount,
  refetch,
  setStakingActivity,
}) => {
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [depositError, setDepositError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const { address } = useAccount();

  const { config } = usePrepareContractWrite({
    ...DepositoryContract,
    functionName: "deposit",
    args: [parseEther(stakeAmount || "0")],
    onSuccess(data) {
      setDepositError(false);
      setErrorMsg("");
    },
    onError(error) {
      setDepositError(true);
      setErrorMsg(error.cause.reason);
      // console.log(error.cause.reason);
      // console.log(error);
    },
  });

  const { write: Deposit, data: depositData } = useContractWrite({
    ...config,
    onSuccess(data) {
      setBtnLoading(true);
    },
    onError(error) {
      //   setErrorMsg(data.message);
      //   NotifyError(data.message);
      setBtnLoading(false);
      toast.error(error.shortMessage);
      //   console.log(error);
    },
  });

  const { isSuccess } = useWaitForTransaction({
    hash: depositData?.hash,

    onError(error) {
      setBtnLoading(false);
      toast.error(error.shortMessage);
    },
  });

  useEffect(() => {
    const handleSuccess = async () => {
      if (isSuccess) {
        const res = await getUserDepositoryEvents(address);
        setStakingActivity(res);

        setBtnLoading(false);
        setStakeAmount("");
        refetch();
        toast.success("Deposit successful");
      }
    };
    handleSuccess();
  }, [isSuccess]);

  const handleDepositClick = () => {
    if (!stakeAmount) {
      setErrorMsg("Please enter an amount to deposit");
      return;
    }
    if (depositError) {
      toast.error(errorMsg);
      return;
    }

    setBtnLoading(true);
    Deposit?.();
  };

  return (
    <Button
      onClick={handleDepositClick}
      disabled={isBtnLoading || !stakeAmount || fieldError}
      className="bg-gold my-6 text-lg py-2"
      fullWidth
    >
      {isBtnLoading ? "DEPOSITING..." : "DEPOSIT"}
    </Button>
  );
};

export default DepositBtn;
