import React, { useState } from "react";

// import { toast } from "sonner";
// import { getDepositors } from "../hooks/FetchContractEvents";
import AdminSidebar from "../components/Admin/AdminSidebar";
import EmergencySettings from "../components/Admin/EmergencySettings";
import RevenueShareSettings from "../components/Admin/RevenueShareSettings";
import { useAccount } from "wagmi";
import AccessRestricted from "../components/Admin/AccessRestricted";
import DividendReports from "../components/Admin/DividendReports";
import { adminAddresses } from "../constants";

const Admin = () => {
  const [activeTab, setActiveTab] = useState(1);

  const { isConnected, address } = useAccount();

  return (
    <div>
      {isConnected && adminAddresses.includes(address) ? (
        <div className="min-h-screen">
          <div className="fixed top-6 right-10 ">
            <w3m-button size="sm" />
          </div>
          <AdminSidebar activeTab={activeTab} setActiveTab={setActiveTab} />

          {/* Different tabs */}
          <div className="px-14 md:ml-[7rem] lg:ml-[21rem] exceeded:ml-0">
            {activeTab === 1 ? (
              <RevenueShareSettings />
            ) : activeTab === 2 ? (
              <DividendReports />
            ) : (
              <EmergencySettings />
            )}
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center h-screen">
          <AccessRestricted />
        </div>
      )}
    </div>
  );
};

export default Admin;
