import React from "react";
import { useNavigate } from "react-router-dom";
import { adminSidebarNavLinks } from "../../constants";
import { ReactComponent as Disconnect } from "../../assets/disconnect.svg";
// import { ReactComponent as Documentation } from "../../assets/documentation.svg";
// import Logo from "../../assets/logo.png";
import { useDisconnect } from "wagmi";

const AdminSidebar = ({ activeTab, setActiveTab }) => {
  const { disconnect } = useDisconnect();

  const navigate = useNavigate();

  const handleDisconnect = () => {
    disconnect();
    navigate("/");
  };
  return (
    <div className="h-screen hidden  w-[7rem] lg:w-[21rem] p-4 bg-secondary border-r-[0.25px] border-gray-800 md:flex flex-col justify-between fixed exceeded:sticky left-0 top-0">
      <div className="">
        <div className="mb-6 lg:mb-2 p-4">
          {/* <img src={Logo} alt="gng" className="w-[100px]" /> */}
          <span className="text-gold text-3xl font-bold">ADMIN</span>
        </div>
        <div className="flex flex-col items-center  lg:items-stretch  lg:justify-start h-full gap-4">
          {/* =============== NAV LINKS =============== */}
          <div className="flex flex-col gap-4  lg:gap-2 lg:min-w-[240px] p-2 font-sans text-base font-normal text-grey">
            {adminSidebarNavLinks.map((link) => (
              <button
                onClick={() => setActiveTab(link.id)}
                key={link.label}
                className={`flex items-center   lg:w-full p-4 lg:p-3 rounded-lg text-start leading-tight transition-all outline-none cursor-pointer ${
                  activeTab === link.id
                    ? "bg-gold bg-opacity-30 text-white"
                    : "hover:bg-gold hover:bg-opacity-30 hover:text-white group"
                } `}
              >
                <link.icon
                  className={`h-5 w-5 ${
                    activeTab === link.id
                      ? "text-gold"
                      : "group-hover:text-gold"
                  }`}
                />
                <span className="pl-4 lg:block hidden">{link.label}</span>
              </button>
            ))}
          </div>

          {/* =============== SEPARATOR =============== */}
          <div className="px-2 lg:block hidden">
            <div className="h-[0.25px] bg-gray-800" />
          </div>

          <div className="absolute bottom-4 lg:static flex flex-col gap-2 lg:min-w-[240px] p-2 font-sans text-base font-normal text-grey">
            {/* =============== DOCUMENTATION =============== */}
            {/* <button className="flex items-center cursor-pointer w-full p-3 rounded-lg text-start leading-tight transition-all hover:bg-gold hover:bg-opacity-30    hover:text-white  outline-none group">
              <Documentation className="h-5 w-5  group-hover:text-gold" />
              <span className="pl-4 lg:block hidden">Docs</span>
            </button> */}

            {/* =============== DISCONNECT =============== */}
            <button
              onClick={handleDisconnect}
              className="flex items-center cursor-pointer w-full p-3 rounded-lg text-start leading-tight transition-all hover:bg-gold hover:bg-opacity-30    hover:text-white  outline-none group"
            >
              <Disconnect className="h-5 w-5  group-hover:text-gold" />
              <span className="pl-4 lg:block hidden">Log out</span>
            </button>
          </div>
        </div>
      </div>
      {/* <div className="hidden lg:flex justify-center ">
        <w3m-button size="sm" balance="hidden" />
      </div> */}
    </div>
  );
};

export default AdminSidebar;
