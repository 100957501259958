import React from "react";
import { Drawer } from "@material-tailwind/react";

import { Link, useLocation } from "react-router-dom";
import { sidebarNavLinks } from "../../../constants";
import { ReactComponent as Disconnect } from "../../../assets/disconnect.svg";
import { ReactComponent as Documentation } from "../../../assets/documentation.svg";
// import Logo from "../../../assets/logo.png";
import { useDisconnect } from "wagmi";
import { useNavigate } from "react-router-dom";
import Logo from "../../../assets/PngvnLogoDesktop.png";
import { XMarkIcon } from "@heroicons/react/24/solid";

export function MobileNav({ setIsDrawerOpen, isDrawerOpen }) {
  const { disconnect } = useDisconnect();
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();

  const handleDisconnect = () => {
    disconnect();
    setIsDrawerOpen(false);
    navigate("/");
  };
  const closeDrawer = () => setIsDrawerOpen(false);

  return (
    <div className="md:hidden">
      <Drawer open={isDrawerOpen} onClose={closeDrawer}>
        <div className="h-screen   w-[300px] p-4 bg-secondary border-r-[0.25px] border-gray-900 flex flex-col justify-between">
          <div className="">
            <div className="mb-2 p-4 flex justify-between items-center">
              <img src={Logo} alt="gng" className="w-[100px]" />
              <button
                onClick={closeDrawer}
                className="hover:bg-gold hover:bg-opacity-30 rounded-md p-1"
              >
                <XMarkIcon className="h-6 w-6 stroke-2 text-white" />
              </button>
            </div>
            <div className="flex flex-col items-stretch  justify-start h-full gap-4">
              {/* =============== NAV LINKS =============== */}
              <div className="flex flex-col gap-2 min-w-[240px] p-2 font-sans text-base font-normal text-grey">
                {sidebarNavLinks.map((link) => (
                  <Link
                    onClick={closeDrawer}
                    to={link.route}
                    key={link.label}
                    className={`flex items-center   w-full p-3 rounded-lg text-start leading-tight transition-all outline-none cursor-pointer ${
                      currentPath === link.route
                        ? "bg-gold bg-opacity-30 text-white"
                        : "hover:bg-gold hover:bg-opacity-30 hover:text-white group"
                    } `}
                  >
                    <link.icon
                      className={`h-5 w-5 ${
                        currentPath === link.route
                          ? "text-gold"
                          : "group-hover:text-gold"
                      }`}
                    />
                    <span className="pl-4 ">{link.label}</span>
                  </Link>
                ))}
              </div>

              {/* =============== SEPARATOR =============== */}
              <div className="px-2">
                <div className="h-[0.25px] bg-gray-900" />
              </div>

              <div className="static flex flex-col gap-2 min-w-[240px] p-2 font-sans text-base font-normal text-grey">
                {/* =============== DOCUMENTATION =============== */}
                <a
                  href="https://docs.pngvntoken.com/"
                  target="_blank"
                  rel="noreferrer"
                  onClick={closeDrawer}
                  className="flex items-center cursor-pointer w-full p-3 rounded-lg text-start leading-tight transition-all hover:bg-gold hover:bg-opacity-30    hover:text-white  outline-none group"
                >
                  <Documentation className="h-5 w-5  group-hover:text-gold" />
                  <span className="pl-4">Docs</span>
                </a>

                {/* =============== DISCONNECT =============== */}
                <button
                  onClick={handleDisconnect}
                  className="flex items-center cursor-pointer w-full p-3 rounded-lg text-start leading-tight transition-all hover:bg-gold hover:bg-opacity-30    hover:text-white  outline-none group"
                >
                  <Disconnect className="h-5 w-5  group-hover:text-gold" />
                  <span className="pl-4">Disconnect</span>
                </button>
              </div>
            </div>
          </div>
          <button onClick={closeDrawer} className="flex justify-center ">
            <w3m-button size="sm" balance="hide" />
          </button>
        </div>
      </Drawer>
    </div>
  );
}
