import React, { useState, useEffect, useContext } from "react";
import StakeCard from "../components/StakeCard";
import UnstakeCard from "../components/UnstakeCard";
import TransactionTable from "../components/shared/TransactionTable";
// import { stakingActivity } from "../constants";
import { useAccount, useContractRead } from "wagmi";
import { DepositoryContract } from "../contracts/Depository";
// import { useContractEvent } from "wagmi";
import { getUserDepositoryEvents } from "../hooks/FetchContractEvents";
import { DappContext } from "../context/Dapp.context";
import { formatEther } from "viem";

const Staking = () => {
  const [stakeStatus, setStakeStatus] = useState(true);
  const [stakingActivity, setStakingActivity] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [depositedBalance, setDepositedBalance] = useState("");

  const { address } = useAccount();
  const { wrongNetwork } = useContext(DappContext);

  const { refetch } = useContractRead({
    ...DepositoryContract,
    functionName: "balances",
    args: [address],
    watch: true,
    onSuccess(data) {
      setDepositedBalance(formatEther(data));
    },
  });

  useEffect(() => {
    setLoaded(false);
    const fetchData = async () => {
      const res = await getUserDepositoryEvents(address);

      setStakingActivity(res);
      setLoaded(true);
    };

    if (!wrongNetwork) {
      fetchData();
    }
  }, [address, wrongNetwork]);

  // useContractEvent({
  //   address: DepositoryContract.address,
  //   abi: DepositoryContract.abi,
  //   eventName: "Deposited",
  //   listener(log) {
  //     console.log(log);
  //   },
  // });

  // console.log(res);

  return (
    <div className="mt-10">
      <div className="px-4 md:px-14 mb-10">
        <div className="flex flex-col gap-4">
          <h1 className="text-light text-4xl font-semibold">Depository</h1>
          <p className="text-darkgray font-normal">
            Hold your PNGVN tokens safely and securly on our depository page so
            that you are able to easily claim your dividend quarterly rewards.
            Follow the onpage instructions and gain the maximum benefits as a
            PNGVN token hodler.
          </p>
        </div>

        <div className="mt-20 flex flex-col gap-2 md:gap-0 md:flex-row md:justify-between md:items-center text-[24px]">
          <div className="flex gap-6">
            <button
              onClick={() => setStakeStatus(true)}
              className={
                stakeStatus
                  ? "relative pb-2  duration-300 text-gold"
                  : "relative text-darkgray pb-2  duration-300 opacity-50 hover:opacity-100 hover:text-light transition-all"
              }
            >
              Deposit
              <span
                className={
                  stakeStatus ? "underlineActive" : "underlineInactive"
                }
              />
            </button>
            <button
              onClick={() => setStakeStatus(false)}
              className={
                stakeStatus
                  ? "relative text-darkgray pb-2  duration-300 opacity-50 hover:opacity-100 hover:text-light transition-all"
                  : "relative pb-2  duration-300 text-gold"
              }
            >
              Withdraw
              <span
                className={
                  stakeStatus ? "underlineInactive" : "underlineActive"
                }
              />
            </button>
          </div>

          <div className="flex flex-col">
            <div className="text-gold">
              Deposited balance: {depositedBalance}
            </div>
            {/* <p className="text-darkgray text-xs">
              You currencly have a 0 deposited balance. In order to qualify for
              dividend payouts you must deposit tokens to the depository
            </p> */}
          </div>
        </div>

        {stakeStatus ? (
          <StakeCard setStakingActivity={setStakingActivity} />
        ) : (
          <UnstakeCard setStakingActivity={setStakingActivity} />
        )}
      </div>

      <TransactionTable
        title="All Previous Depository Txn’s"
        activity={stakingActivity}
        loaded={loaded}
      />
    </div>
  );
};

export default Staking;
