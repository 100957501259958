import { Button } from "@material-tailwind/react";
import React, { useState } from "react";
import { DepositoryContract } from "../../contracts/Depository";
import {
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
} from "wagmi";
import { toast } from "sonner";

const PauseClaimBtn = ({ setContractClaimStatus }) => {
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [prepareError, setPrepareError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const { config } = usePrepareContractWrite({
    ...DepositoryContract,
    functionName: "setClaimStatus",
    args: [0],
    onSuccess(data) {
      //   setFieldError(false);
      //   setErrorMsg("");
      setPrepareError(false);
      setErrorMsg("");
    },
    onError(error) {
      // setFieldError(true);
      // setErrorMsg(error.cause.reason);
      // console.log(error.cause.reason);
      // console.log(error);
      setPrepareError(true);
      setErrorMsg(error.shortMessage);
    },
  });

  //   console.log(errorMsg);

  const { write: PauseClaim, data: txnData } = useContractWrite({
    ...config,
    onSuccess(data) {
      setBtnLoading(true);
    },
    onError(error) {
      //   setErrorMsg(data.message);
      //   NotifyError(data.message);
      setBtnLoading(false);
      toast.error(error.shortMessage);
      //   console.log(error);
    },
  });

  useWaitForTransaction({
    hash: txnData?.hash,
    onSuccess(data) {
      setBtnLoading(false);
      toast.success("Claim paused");
      setContractClaimStatus(false);
    },
    onError(error) {
      //   NotifyError(error.message);
      //   setBtnLoading(false);
      setBtnLoading(false);
      toast.error(error.shortMessage);
    },
  });

  const handleClick = () => {
    if (prepareError) {
      toast.error(errorMsg);
      return;
    }

    setBtnLoading(true);
    PauseClaim?.();
  };

  return (
    <div>
      <Button disabled={isBtnLoading} onClick={handleClick} className="bg-gold">
        {isBtnLoading ? "Pausing..." : "Pause claim"}
      </Button>
    </div>
  );
};

export default PauseClaimBtn;
