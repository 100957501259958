import { createContext, useState, useEffect } from "react";
import { useDisconnect, useNetwork, useAccount } from "wagmi";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";

// as the actual value we want to access
export const DappContext = createContext({
  currentUser: null,
  setCurrentUser: () => null,
  data: null,
  setData: () => null,
  mounted: null,
  setMounted: () => null,
});

export const DappProvider = ({ children }) => {
  // const [currentNetwork, setCurrentNetwork] = useState(null);
  const [wrongNetwork, setWrongNetwork] = useState(true);
  const [mounted, setMounted] = useState(false);
  const [statData, setStatData] = useState([]);

  const { disconnect } = useDisconnect();
  const { chain } = useNetwork();
  const { address, isConnected } = useAccount();
  const navigate = useNavigate();

  // console.log(chain?.id);

  const value = { wrongNetwork, statData, setStatData, mounted, setMounted };

  useEffect(() => {
    disconnect();
    navigate("/");
  }, []);

  useEffect(() => {
    setMounted(false);
  }, [address]);

  useEffect(() => {
    if (!isConnected) {
      navigate("/");
    }
  }, [isConnected]);

  useEffect(() => {
    // TODO - CHANGE BACK TO MAINNET
    // if (isConnected && chain?.id !== 11155111) {
    if (isConnected && chain?.id !== 1) {
      setWrongNetwork(true);
      toast.error("Please connect to Ethereum mainnet");
    } else if (isConnected) {
      setWrongNetwork(false);
    }
    // console.log(chain?.id === 11155111);
  }, [chain]);

  return <DappContext.Provider value={value}>{children}</DappContext.Provider>;
};
