import { useContext } from "react";
import HamburgerMenu from "./HamburgerMenu";
import { DappContext } from "../../../context/Dapp.context";
import DesktopLogo from "../../../assets/PngvnLogoDesktop.png";

const Header = ({ setIsDrawerOpen }) => {
  const { wrongNetwork } = useContext(DappContext);
  return (
    <div className="flex items-center justify-between md:justify-end text-white h-[80px] border-b border-gray-900 px-6 md:px-10 sticky top-0  z-10 bg-primary">
      {/* <GlobalSearch /> */}
      <img src={DesktopLogo} alt="gng" className="w-[120px]  md:hidden" />

      <HamburgerMenu setIsDrawerOpen={setIsDrawerOpen} />
      <div className="hidden md:flex items-center gap-2">
        {wrongNetwork && (
          <div className=" h-[30px] border border-red-500 rounded-full bg-red-500 bg-opacity-30 flex justify-center items-center px-4 text-light">
            Wrong network
          </div>
        )}
        <w3m-button balance="hidden" />
      </div>
    </div>
  );
};

export default Header;
