import React, { useState } from "react";
import { Button } from "@material-tailwind/react";
import {
  // addCommasToIntegerString,
  blockInvalidCharWithDecimal,
} from "../lib/utils";
import {
  useBalance,
  useAccount,
  useContractRead,
  // usePrepareContractWrite,
  // useContractWrite,
  // useWaitForTransaction,
} from "wagmi";
import { GngTokenContract } from "../contracts/GngToken";
import { DepositoryContract } from "../contracts/Depository";
import { formatEther } from "viem";
// import { ethers } from "ethers";
import ApproveBtn from "./ApproveBtn";
import DepositBtn from "./DepositBtn";

const StakeCard = ({ setStakingActivity }) => {
  const [balance, setBalance] = useState("0");
  const [stakeAmount, setStakeAmount] = useState("");
  const [fieldError, setFieldError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [allowance, setAllowance] = useState("");
  const [approved, setApproved] = useState(false);
  const { address } = useAccount();

  // console.log(errorMsg);

  const { refetch } = useBalance({
    address: address,
    token: GngTokenContract.address,
    onSuccess(data) {
      // console.log(data.formatted);
      setBalance(data.formatted);
    },
  });

  useContractRead({
    ...GngTokenContract,
    functionName: "allowance",
    args: [address, DepositoryContract.address],
    watch: true,
    onSuccess(data) {
      setAllowance(formatEther(data));

      if (Number(formatEther(data)) > 0) {
        setApproved(true);
      } else {
        setApproved(false);
      }
    },
  });
  // console.log(allowance);

  const handleStakeFieldChange = (e) => {
    const value = e.target.value;

    setStakeAmount(value);
  };

  const setMax = () => {
    setStakeAmount(balance);
  };

  // TODO check to add function as such: allowance.gt(stakeAmount) ? <DepositBtn /> : <ApproveBtn />
  // console.log(allowance);

  return (
    <div className="flex justify-center mt-14 md:mt-20">
      {/* <div className="h-[300px] w-[600px]  rounded-xl border border-gold bg-secondary">
        <h1 className="text-2xl text-center py-5 border-b-[0.25px] border-gray-800   text-gold">
          <span className="inline-flex animate-text-gradient bg-gradient-to-r from-[#FFD47F]  via-[#E1AE57] to-[#9E712C] bg-[200%_auto] bg-clip-text text-2xl text-transparent">
            GNG Staking
          </span>
        </h1>
      </div> */}
      <div className="relative mb-10 sm:w-[450px] md:w-[525px] xl:w-[600px] overflow-hidden rounded-xl border border-gray-800 p-[1px] backdrop-blur-3xl fadeUp">
        <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#9E712C_0%,#E1AE57_50%,#9E712C_100%)]" />
        <div className=" h-full w-full  rounded-xl bg-secondary  text-sm font-medium text-gray-50 backdrop-blur-3xl">
          <h1 className="text-2xl text-center py-5 border-b-[0.25px] border-gray-800   text-gold">
            <span className="inline-flex animate-text-gradient bg-gradient-to-r from-[#FFD47F]  via-[#E1AE57] to-[#9E712C] bg-[200%_auto] bg-clip-text text-2xl text-transparent">
              PNGVN Deposit
            </span>
          </h1>

          <div className=" px-10">
            <div className="flex items-center justify-end font-thin text-lg text-grey mt-10">
              {/* <span>Enter amount</span> */}
              <div>
                Wallet balance:{" "}
                <span className="font-normal text-light">
                  {/* {addCommasToIntegerString(balance)} */}
                  {/* {addCommasToIntegerString("250,000,000.837468876526374859")} */}
                  {balance}
                </span>
              </div>
            </div>

            <div className="pl-4 pr-2 mt-4 bg-[#1A1A21] border border-gray-800 rounded-lg flex items-center justify-between gap-4 ">
              <input
                onKeyDown={blockInvalidCharWithDecimal}
                type="number"
                spellCheck={false}
                onWheel={(e) => e.target.blur()}
                className="w-full h-14 text-light text-xl outline-none bg-transparent  truncate placeholder:font-thin placeholder:text-gray-500 "
                value={stakeAmount}
                onChange={handleStakeFieldChange}
                placeholder="Enter amount"
              />

              <Button className="bg-black text-light" onClick={setMax}>
                Max
              </Button>
            </div>

            {approved ? (
              // <Button
              //   disabled={fieldError || !stakeAmount}
              //   className="bg-gold my-6 text-lg py-2"
              //   fullWidth
              // >
              //   DEPOSIT GNG
              // </Button>
              <DepositBtn
                stakeAmount={stakeAmount}
                fieldError={fieldError}
                setStakeAmount={setStakeAmount}
                refetch={refetch}
                setStakingActivity={setStakingActivity}
              />
            ) : (
              <ApproveBtn setApproved={setApproved} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StakeCard;
// from-[#9E712C]  via-[#E1AE57] to-[#FFD47F]
