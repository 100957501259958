import { useSpring, animated } from "react-spring";
import keccak256 from "keccak256";
import MerkleTree from "merkletreejs";
import { Buffer } from "buffer";
import { ethers, utils } from "ethers";

export const getElapsedTime = (unixTimestamp) => {
  const currentTimestamp = Math.floor(new Date().getTime() / 1000); // Convert to seconds
  const timeDifference = currentTimestamp - unixTimestamp;

  const minute = 60;
  const hour = 60 * minute;
  const day = 24 * hour;
  const week = 7 * day;
  const month = 30 * day;
  const year = 365 * day;

  if (timeDifference < minute) {
    const seconds = Math.floor(timeDifference);

    return `${seconds} ${seconds === 1 ? "second" : "seconds"}`;
  } else if (timeDifference < hour) {
    const minutes = Math.floor(timeDifference / minute);

    return `${minutes} ${minutes === 1 ? "minute" : "minutes"}`;
  } else if (timeDifference < day) {
    const hours = Math.floor(timeDifference / hour);

    return `${hours} ${hours === 1 ? "hour" : "hours"}`;
  } else if (timeDifference < week) {
    const days = Math.floor(timeDifference / day);

    return `${days} ${days === 1 ? "day" : "days"}`;
  } else if (timeDifference < month) {
    const weeks = Math.floor(timeDifference / week);

    return `${weeks} ${weeks === 1 ? "week" : "weeks"}`;
  } else if (timeDifference < year) {
    const months = Math.floor(timeDifference / month);

    return `${months} ${months === 1 ? "month" : "months"}`;
  } else {
    const years = Math.floor(timeDifference / year);

    return `${years} ${years === 1 ? "year" : "years"}`;
  }
};

export const formatNumber = (num) => {
  if (num >= 1000000) {
    const formattedNum = (num / 1000000).toFixed(1);
    return `${formattedNum}M`;
  } else if (num >= 1000) {
    const formattedNum = (num / 1000).toFixed(1);
    return `${formattedNum}K`;
  } else {
    return num.toString();
  }
};

export const formatStrictNumber = (num) => {
  if (num >= 1000000) {
    const formattedNum = (num / 1000000).toFixed(1);
    return formattedNum.toString();
  } else if (num >= 1000) {
    const formattedNum = (num / 1000).toFixed(1);
    return formattedNum.toString();
  } else {
    return num.toString();
  }
};

export const formatDenominator = (num) => {
  if (num >= 1000000) {
    return "M";
  } else if (num >= 1000) {
    return "K";
  } else {
    return "";
  }
};

export const addCommasToIntegerString = (integerStr) => {
  // Remove commas from the input
  const cleanedIntegerStr = integerStr.replace(/,/g, "");

  // Check if the cleaned input is a valid number
  const floatValue = parseFloat(cleanedIntegerStr);
  if (isNaN(floatValue)) {
    return "Invalid input";
  }

  // Convert the float to string and split it into integer and fractional parts
  const [integerPart, fractionalPart] = floatValue.toString().split(".");

  // Add commas to the integer part
  const parts = [];
  let part = "";
  let count = 0;

  for (let i = integerPart.length - 1; i >= 0; i--) {
    part = integerPart[i] + part;
    count++;

    if (count === 3 || i === 0) {
      parts.unshift(part);
      part = "";
      count = 0;
    }
  }

  // Join the integer part with commas
  let result = parts.join(",");

  // Append the fractional part if it exists
  if (fractionalPart !== undefined) {
    result += "." + fractionalPart;
  }

  return result;
};

export const NumberAnimation = ({ n }) => {
  const { number } = useSpring({
    from: { number: 0 },
    number: n,
    delay: 200,
    config: { mass: 1, tension: 20, friction: 10 },
  });

  return <animated.div>{number.to((n) => n.toFixed(2))}</animated.div>;
};

export const NumberAnimationNoDecimals = ({ n }) => {
  const { number } = useSpring({
    from: { number: 0 },
    number: n,
    delay: 200,
    config: { mass: 1, tension: 20, friction: 10, duration: 1200 },
  });

  return <animated.div>{number.to((n) => n.toFixed())}</animated.div>;
};

export const spliceHash = (hash) =>
  `${hash.slice(0, 2)}...${hash.slice(hash.length - 5)}`;

export const spliceRoot = (root) =>
  `${root.slice(0, 2)}...${root.slice(root.length - 3)}`;

export const blockInvalidCharWithDecimal = (e) =>
  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

export const generateMerkleProof = (merkleData, address, revShare) => {
  window.Buffer = window.Buffer || Buffer;

  const leaves = merkleData.map(({ id, share }) => {
    const encodedData = utils.solidityPack(["address", "uint256"], [id, share]);

    return keccak256(encodedData);
  });

  const tree = new MerkleTree(leaves, keccak256, { sortPairs: true }); // Create the Merkle tree
  const buf2hex = (x) => "0x" + x.toString("hex");

  // Encode the data using Ethereum ABI encoding
  const userEncodedData = utils.solidityPack(
    ["address", "uint256"],
    [address, revShare]
  );

  // Calculate the leaf hash using keccak256 from ethers
  const leaf = utils.keccak256(userEncodedData);

  // Get the Merkle proof for the given address and amount
  const proof = tree.getProof(leaf).map((proofItem) => buf2hex(proofItem.data));

  return proof;
};

export const generateMerkleRoot = (merkleData) => {
  window.Buffer = window.Buffer || Buffer;

  const leaves = merkleData.map(({ id, share }) => {
    const account = utils.getAddress(id);
    // const weiAmount = ethers.utils.parseEther(amount);

    const encodedData = utils.solidityPack(
      ["address", "uint256"],
      [account, share]
    );

    return keccak256(encodedData);
  });

  const tree = new MerkleTree(leaves, keccak256, { sortPairs: true }); // Create the Merkle tree
  const buf2hex = (x) => "0x" + x.toString("hex");
  const root = buf2hex(tree.getRoot());

  return root;
};

export const getLeaf = (address, amount) => {
  // const checkSummedAddress = utils.getAddress(address);
  // const weiAmount = ethers.utils.parseEther(amount);

  const userEncodedData = utils.solidityPack(
    ["address", "uint256"],
    [address, amount]
  );

  return utils.keccak256(userEncodedData);
};

// TODO - GENERATE ROOT FOR INITIAL TOKEN CLAIM USING THIS FUNCTION
export const generateMerkleProofConvertingToWeiValues = (
  merkleData,
  address,
  revShare
) => {
  window.Buffer = window.Buffer || Buffer;

  const leaves = merkleData.map(({ id, share }) => {
    const account = utils.getAddress(id);
    const weiAmount = ethers.utils.parseEther(share);

    const encodedData = utils.solidityPack(
      ["address", "uint256"],
      [account, weiAmount]
    );

    return keccak256(encodedData);
  });
  const tree = new MerkleTree(leaves, keccak256, { sortPairs: true });
  const buf2hex = (x) => "0x" + x.toString("hex");
  const root = buf2hex(tree.getRoot());

  const userAddress = utils.getAddress(address);
  const weiRevShareAmount = ethers.utils.parseEther(revShare);

  const userEncodedData = utils.solidityPack(
    ["address", "uint256"],
    [userAddress, weiRevShareAmount]
  );

  const leaf = utils.keccak256(userEncodedData);

  const proof = tree.getProof(leaf).map((proofItem) => buf2hex(proofItem.data));

  // console.log("use Root for Wei Vals:", root);

  return proof;
};

export const getLeafConvertingToWeiValues = (address, amount) => {
  const checkSummedAddress = utils.getAddress(address);
  const weiAmount = ethers.utils.parseEther(amount);

  const userEncodedData = utils.solidityPack(
    ["address", "uint256"],
    [checkSummedAddress, weiAmount]
  );

  return utils.keccak256(userEncodedData);
};
