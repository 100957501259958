import { useState } from "react";
import { useAccount } from "wagmi";

import { Sidebar } from "./components/shared/Navigation/Sidebar";
import { MobileNav } from "./components/shared/Navigation/MobileNav";
// import GlobalSearch from "./components/shared/GlobalSearch";
// import HamburgerMenu from "./components/shared/Navigation/HamburgerMenu";
import Stats from "./components/shared/Stats";
import Home from "./pages/Home";
import SignIn from "./pages/SignIn";

import { Routes, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";
import NotFound from "./pages/NotFound";
import Staking from "./pages/Staking";
import RevenueShare from "./pages/RevenueShare";
import Support from "./pages/Support";
import ScrollToTop from "./components/shared/ScrollToTop";
import Footer from "./components/shared/Footer";
import { Toaster } from "sonner";
import "react-loading-skeleton/dist/skeleton.css";
import Admin from "./pages/Admin";
import Header from "./components/shared/Navigation/Header";
import ClaimTokens from "./pages/ClaimTokens";
// import Logo from "../src/assets/logo.png";
// import DesktopLogo from "../src/assets/PngvnLogoDesktop.png";

function App() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { isConnected } = useAccount();
  const location = useLocation();

  const pathname = location.pathname;

  const is404Page =
    location.pathname !== "/" &&
    location.pathname !== "/qrevenue-share" &&
    location.pathname !== "/qdepository";
  // && location.pathname !== "/support"; // Commented here to hide <Stats /> component on the support page

  // Todo: check to add in real time updates to <Stats /> component upon claims / withdrawals / deposits
  // Todo: uncomment tokensClaim page once its ready to go
  return (
    <main className="relative bg-primary">
      {pathname === "/admin" && isConnected ? (
        <div className="bg-black text-white">
          <Toaster richColors position="bottom-right" />
          <Admin />
        </div>
      ) : isConnected ? (
        <div className="flex max-w-[2000px] exceeded:border-x exceeded:border-gray-800 mx-auto">
          <ScrollToTop />
          <Toaster richColors position="bottom-right" />
          <Sidebar />
          <MobileNav
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
          />

          <section className="flex min-h-screen flex-1 flex-col  max-md:pb-14 md:ml-[7rem] lg:ml-[21rem] exceeded:ml-0">
            {/* <div className="flex items-center justify-between text-white h-[80px] border-b border-gray-800 px-10 sticky top-0 bg-primary  z-10">
              <GlobalSearch />
              <HamburgerMenu
                isDrawerOpen={isDrawerOpen}
                setIsDrawerOpen={setIsDrawerOpen}
              />
              <div className="hidden md:block">
                <w3m-button balance="hidden" />
              </div>
            </div> */}
            <Header setIsDrawerOpen={setIsDrawerOpen} />
            {!is404Page && <Stats />}
            <div className="mx-auto w-full text-white">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/revenue-share" element={<RevenueShare />} />
                <Route path="/depository" element={<Staking />} />
                <Route path="/claim-tokens" element={<ClaimTokens />} />
                <Route path="/support" element={<Support />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>

            {!is404Page && <Footer />}
          </section>
        </div>
      ) : (
        <SignIn />
      )}
    </main>
  );
}

export default App;
