import { ReactComponent as Dashboard } from "../assets/dashboard.svg";
import { ReactComponent as Staking } from "../assets/staking.svg";
import { ReactComponent as RevenueShare } from "../assets/revenue-share.svg";
import { ReactComponent as Support } from "../assets/support.svg";
import { ReactComponent as Token } from "../assets/token.svg";

// import { ReactComponent as AdminEmergency } from "../assets/admin-emergency.svg";
import { ReactComponent as AdminSettings } from "../assets/admin-settings2.svg";
import { DocumentIcon } from "@heroicons/react/24/outline";
import { Cog6ToothIcon } from "@heroicons/react/24/outline";

export const adminAddresses = [
  "0x7667A06a7b0c7E3185da4FA4e452A9d1cd24D9dB",
  "0x7a60A79582B92257D3dE1515d0815198923809cb",
  "0x48caade36c64a1e91715328b1cdebee3057c360c",
];

// Todo: uncomment claim-tokens page once its ready to go
export const sidebarNavLinks = [
  { icon: Dashboard, route: "/", label: "Home" },
  { icon: RevenueShare, route: "/revenue-share", label: "Revenue Share" },
  { icon: Staking, route: "/depository", label: "Depository" },
  { icon: Token, route: "/claim-tokens", label: "Tokens Claim" },
  { icon: Support, route: "/support", label: "Support" },
];

export const adminSidebarNavLinks = [
  // { id: 1, icon: Dashboard, label: "Home" },
  {
    id: 1,
    icon: AdminSettings,
    label: "Create dividend round",
  },
  { id: 2, icon: DocumentIcon, label: "Dividend reports" },
  { id: 3, icon: Cog6ToothIcon, label: "Settings" },
  // { id: 4, icon: Support, label: "Support" },
];

export const footerLinks = [
  { id: 1, label: "Docs", link: "https://docs.pngvntoken.com/" },
  {
    id: 2,
    label: "Privacy Policy",
    link: "https://pngvntoken.com/privacy-policy-pngvn-app/",
  },
  { id: 3, label: "Website", link: "https://pngvntoken.com/" },
  { id: 4, label: "Twitter", link: "https://x.com/PNGVNToken/" },
  { id: 5, label: "Telegram", link: "https://t.me/PNGVNToken" },
  { id: 5, label: "Instagram", link: "https://www.instagram.com/PNGVNToken" },
  { id: 5, label: "Facebook", link: "https://www.facebook.com/PNGVNToken" },
];

// PLACEHOLDERS
export const placeholderStats = [
  {
    name: "Total user deposited",
    value: "10",
    change: "+4.75%",
    changeType: "positive",
  },
  {
    name: "Total user claims",
    value: "10.59",
    change: "+54.02%",
    changeType: "negative",
  },
  {
    name: "Total community payouts",
    value: "109",
    change: "+1.39%",
    changeType: "positive",
  },
];

export const placeholderClaimActivity = [
  {
    id: 1,
    event: "Share claim",
    timestamp: "1709045033",
    amount: "10.8",
    hash: "0xa062218a01753b308a413c13541874db291dc0bb8090daefcaa15c8cd39843db",
  },
  {
    id: 2,
    event: "Share claim",
    timestamp: "1698045033",
    amount: "17.33",
    hash: "0x0bfe79d95a5d9f739f037e5cec529ed802435f5352085e5ab5e0e59b06d36620",
  },
  {
    id: 3,
    event: "Share claim",
    timestamp: "1689045033",
    amount: "0.80",
    hash: "0x5069bdc30184d19ac6e890bc67f23570e4ebad949c35dc2f9628729f8f2ceb87",
  },
];

export const placeholderStakingActivity = [
  {
    id: 1,
    event: "Stake",
    timestamp: "1709045033",
    amount: "1746236",
    hash: "0xa062218a01753b308a413c13541874db291dc0bb8090daefcaa15c8cd39843db",
  },
  {
    id: 2,
    event: "Unstake",
    timestamp: "1698045033",
    amount: "946236",
    hash: "0x0bfe79d95a5d9f739f037e5cec529ed802435f5352085e5ab5e0e59b06d36620",
  },
  {
    id: 3,
    event: "Stake",
    timestamp: "1689045033",
    amount: "1000293",
    hash: "0x5069bdc30184d19ac6e890bc67f23570e4ebad949c35dc2f9628729f8f2ceb87",
  },
];

export const placeholderChartData = [
  {
    revenue: 0.25,
    // subscription: 240,
  },
  {
    revenue: 1.37,
    // subscription: 300,
  },
  {
    revenue: 0.54,
    // subscription: 200,
  },
  {
    revenue: 2.3,
    // subscription: 278,
  },
  {
    revenue: 0.12,
    // subscription: 189,
  },
  {
    revenue: 0.05,
    // subscription: 239,
  },
  {
    revenue: 5,
    // subscription: 278,
  },
  {
    revenue: 20,
    // subscription: 189,
  },
];
