import React, { useState } from "react";
import { blockInvalidCharWithDecimal } from "../../lib/utils";
import { Button } from "@material-tailwind/react";
import { parseEther } from "viem";
import { toast } from "sonner";
import { getDepositors } from "../../hooks/FetchContractEvents";

const GenerateShares = ({
  // ethSharePool,
  // setEthSharePool,
  setActiveStep,
  setShares,
  //
  ethPerTokenRate,
  setEthPerTokenRate,
}) => {
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  const handleFieldChange = (e) => {
    const value = e.target.value;
    setEthPerTokenRate(value);
    // console.log(parseEther(value).toString());
  };

  const handleGenerateShares = async () => {
    setIsBtnLoading(true);

    try {
      const data = await getDepositors(parseEther(ethPerTokenRate).toString());

      // console.log("data to check", data);
      setShares(data);
      setActiveStep(2);
    } catch (e) {
      console.log(e);
      toast.error("An error occurred generating shares");
    }

    setIsBtnLoading(false);
  };

  return (
    <div>
      <div className="flex flex-col gap-2">
        <span className="text-light text-2xl font-semibold">
          Generate Revenue Shares
        </span>
        <p className="text-darkgray font-normal">
          Generate revenue shares for depositors in relation to their deposited
          TOKENS to ETH rate per token deposited
        </p>
      </div>

      <div className="pl-4 pr-2 mt-4 bg-[#1A1A21] border border-gray-800 rounded-lg flex items-center justify-between gap-4 ">
        <input
          onKeyDown={blockInvalidCharWithDecimal}
          type="number"
          spellCheck={false}
          onWheel={(e) => e.target.blur()}
          className="w-full h-14 text-light text-xl outline-none bg-transparent  truncate placeholder:font-thin placeholder:text-gray-500 "
          value={ethPerTokenRate}
          onChange={handleFieldChange}
          placeholder="Enter ETH rate per Token"
        />

        <span className="text-2xl font-semibold">ETH</span>
      </div>

      <div className="flex justify-end">
        <Button
          className="bg-gold mt-8"
          disabled={isBtnLoading || ethPerTokenRate.length < 1}
          onClick={handleGenerateShares}
        >
          {isBtnLoading ? "Generating..." : "Generate shares"}
        </Button>
      </div>
    </div>
  );
};

export default GenerateShares;
