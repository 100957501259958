import { useEffect, useState } from "react";
import {
  getClaimRounds,
  getDividendRoundReport,
} from "../../hooks/FetchContractEvents";
import { formatEther } from "viem";
import { getElapsedTime } from "../../lib/utils";
import DividendCardTable from "./DividendCardTable";
import RoundReport from "./RoundReport";

const DividendReports = () => {
  const [loaded, setLoaded] = useState(false);
  const [dividendRounds, setDividendRounds] = useState([]);
  const [activeReportDetails, setActiveReportDetails] = useState([]);
  const [activeRoundQuery, setActiveRoundQuery] = useState(0);

  useEffect(() => {
    setLoaded(false);
    const fetchDividendReports = async () => {
      const result = await getClaimRounds();

      setDividendRounds(result);
      setLoaded(true);
    };
    fetchDividendReports();
  }, []);

  //   const testReport = async () => {
  //     const result = await getDividendRoundReport(
  //       activeRoundQuery.toString(),
  //       Number(formatEther(activeReportDetails.args.poolSize))
  //     );
  //     console.log(result.unclaimed);
  //   };

  return (
    <div>
      <div className="flex flex-col gap-4 pt-24">
        <h1 className="text-gold text-4xl font-semibold">Dividend Reports</h1>
        <p className="text-darkgray font-normal">
          This page is dedicated to providing dividend reports for the platform.
          Overview of dividend claim phases as well as individual claim
          activity.
        </p>
      </div>

      {activeRoundQuery > 0 ? (
        <RoundReport
          activeRoundQuery={activeRoundQuery}
          setActiveRoundQuery={setActiveRoundQuery}
          activeReportDetails={activeReportDetails}
        />
      ) : (
        <DividendCardTable
          loaded={loaded}
          dividendRounds={dividendRounds}
          setActiveRoundQuery={setActiveRoundQuery}
          setActiveReportDetails={setActiveReportDetails}
        />
      )}

      {/* <button className="bg-blue-400 rounded-md mt-10" onClick={testReport}>
        CLICK ME TO TRIGGER TEST REPORT
      </button> */}
    </div>
  );
};

export default DividendReports;
