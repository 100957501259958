import { formatEther } from "viem";
import { getElapsedTime } from "../../lib/utils";

const DividendCardTable = ({
  loaded,
  dividendRounds,
  setActiveRoundQuery,
  setActiveReportDetails,
}) => {
  // console.log(dividendRounds);

  const handleGetReportClick = (id) => {
    const matched = dividendRounds.find((obj) => obj.args.roundId == id);
    setActiveReportDetails(matched);
    setActiveRoundQuery(id);
  };

  return (
    <div className=" rounded-xl w-full py-20  pb-6">
      <p className="font-bold text-3xl pb-10">Dividend claim rounds :</p>
      {!loaded ? (
        <div className="w-full pt-10 flex justify-center items-center">
          <div class="loader"></div>
        </div>
      ) : dividendRounds.length > 0 ? (
        dividendRounds.map((round, index) => (
          <div
            key={index}
            className="w-full py-3 border rounded-xl  border-gray-800 px-10 mb-4"
          >
            <div className="flex justify-between items-center h-full">
              <div className="flex flex-col">
                <p className="text-white text-2xl">
                  Round {round.args.roundId.toString()}
                </p>
                <div className="flex gap-3 pt-1 text-lg">
                  <p className="text-gold">
                    {formatEther(round.args.poolSize).toString()} ETH
                  </p>
                  •
                  <p className="text-darkgray">
                    Created {getElapsedTime(round.args.timestamp)} ago
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <button
                  onClick={() => handleGetReportClick(index + 1)}
                  className="bg-gold px-6 py-2 rounded-lg text-white"
                >
                  View details
                </button>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="text-center text-darkgray text-xl pt-10 ">
          No dividend rounds found
        </div>
      )}
    </div>
  );
};

export default DividendCardTable;
