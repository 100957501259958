import React, { useState, useEffect } from "react";
import { formatEther } from "viem";
import { getDividendRoundReport } from "../../hooks/FetchContractEvents";

const RoundReport = ({
  setActiveRoundQuery,
  activeRoundQuery,
  activeReportDetails,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [report, setReport] = useState([]);
  //   console.log(activeReportDetails.args.roundId);

  useEffect(() => {
    setLoaded(false);
    const fetchDividendReports = async () => {
      const result = await getDividendRoundReport(
        activeRoundQuery.toString(),
        Number(formatEther(activeReportDetails.args.poolSize))
      );

      setReport(result);
      setLoaded(true);
    };
    fetchDividendReports();
  }, []);

  return (
    <div>
      {!loaded ? (
        <div className="w-full pt-44 flex justify-center items-center">
          <div class="loader"></div>
        </div>
      ) : report.claims.length > 0 ? (
        <div>
          <p className="font-bold text-3xl pt-20">
            Round {activeRoundQuery} report :
          </p>
          <div className="mt-10 bg-secondary rounded-xl px-10 py-6">
            <span className="text-gold text-2xl">Overview:</span>
            <div className="pt-4 text-lg flex flex-wrap gap-10">
              <div>
                <span className="text-darkgray">Total pool size:</span>{" "}
                {formatEther(activeReportDetails.args.poolSize.toString())} ETH
              </div>
              <div>
                <span className="text-darkgray">Total claimed:</span>{" "}
                {report.claimed} ETH
              </div>
              <div>
                <span className="text-darkgray">Total unclaimed:</span>{" "}
                {report.unclaimed} ETH
              </div>
              <div>
                <span className="text-darkgray">Round status:</span>{" "}
                <span className={`text-${report.status ? "green" : "red"}-600`}>
                  {report.status ? "LIVE" : "INACTIVE"}
                </span>
              </div>
            </div>

            <div className="pt-10">
              <div className="flex justify-between items-center pb-4 text-darkgray">
                <p>User address</p>
                <p>Amount claimed</p>
              </div>

              <div className="flex flex-col gap-2">
                {report.claims.map((claim, index) => (
                  <div
                    key={index}
                    className="flex justify-between items-center w-full"
                  >
                    <p>{claim.args.holder}</p>
                    <p>{formatEther(claim.args.amount)} ETH</p>
                  </div>
                ))}
              </div>
            </div>

            <button
              onClick={() => setActiveRoundQuery(0)}
              className="bg-gold px-6 py-2 rounded-lg text-white mt-10"
            >
              Back
            </button>
          </div>
        </div>
      ) : (
        <div className="text-center text-darkgray text-xl pt-10 ">
          No data found for report
        </div>
      )}
    </div>
  );
};

export default RoundReport;
