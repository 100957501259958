import { useState, useEffect } from "react";

export default function SetNewClaimSteps({ activeStep, setActiveStep }) {
  const [steps, setSteps] = useState([
    { id: 1, tag: "Step 1", name: "Generate shares", status: "" },
    { id: 2, tag: "Step 2", name: "Review and confirm", status: "" },
    { id: 3, tag: "Step 3", name: "Configuring smart contract", status: "" },
    { id: 4, tag: "Step 4", name: "Success", status: "" },
  ]);

  const updateStatus = (indices, newStatusArray) => {
    setSteps((prevSteps) => {
      const newSteps = [...prevSteps];

      indices.forEach((index, i) => {
        if (newSteps[index] && newStatusArray[i]) {
          newSteps[index] = { ...newSteps[index], status: newStatusArray[i] };
        }
      });

      return newSteps;
    });
  };

  useEffect(() => {
    switch (true) {
      case activeStep === 1:
        updateStatus(
          [0, 1, 2, 3],
          ["current", "upcoming", "upcoming", "upcoming"]
        );
        break;
      case activeStep === 2:
        updateStatus(
          [0, 1, 2, 3],
          ["complete", "current", "upcoming", "upcoming"]
        );
        break;
      case activeStep === 3:
        updateStatus(
          [0, 1, 2, 3],
          ["complete", "complete", "current", "upcoming"]
        );
        break;
      case activeStep === 4:
        updateStatus(
          [0, 1, 2, 3],
          ["complete", "complete", "complete", "complete"]
        );
        break;
      default:
        // Render a default component or handle unexpected state values
        console.log("an error has occurred");
        break;
    }
  }, [activeStep]);

  //   const completedStepBackClickHandler = (stepIndex) => {
  //     if (activeStep !== 4) {
  //       setActiveStep(stepIndex);
  //     }
  //   };

  return (
    <nav aria-label="Progress" className="pt-10">
      <ol
        role="list"
        className="space-y-4 md:flex md:space-x-8 md:space-y-0 text-gray-400"
      >
        {steps.map((step) => (
          <li key={step.name} className="md:flex-1">
            {step.status === "complete" ? (
              <div
                // onClick={() => completedStepBackClickHandler(step.id)}
                className="group"
              >
                <div
                  className={`${
                    activeStep !== 4 && " transition-all  duration-300 "
                  } flex flex-col border-l-4 border-gold py-2 pl-4  md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4`}
                >
                  <span className="text-sm font-medium text-gold">
                    {step.tag}
                  </span>
                  <span className="text-sm font-thin">{step.name}</span>
                </div>
              </div>
            ) : step.status === "current" ? (
              <div className="group">
                <div
                  className={`
                  ${activeStep !== 4 && "opacity-60"}
                  flex flex-col border-l-4 border-gold py-2 pl-4 
                    md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4 `}
                  aria-current="step"
                >
                  <span className="text-sm font-medium text-gold">
                    {step.tag}
                  </span>
                  <span className="text-sm font-thin">{step.name}</span>
                </div>
              </div>
            ) : (
              <div className="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4">
                <span className="text-sm font-medium text-gray-400 ">
                  {step.tag}
                </span>
                <span className="text-sm font-thin">{step.name}</span>
              </div>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}
