import React, { useState } from "react";
import { Button } from "@material-tailwind/react";
import { blockInvalidCharWithDecimal } from "../lib/utils";
import { useAccount, useContractRead } from "wagmi";
import { DepositoryContract } from "../contracts/Depository";
import { formatEther } from "viem";
import WithdrawBtn from "./WithdrawBtn";

const UnstakeCard = ({ setStakingActivity }) => {
  const [depositedBalance, setDepositedBalance] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [fieldError, setFieldError] = useState(false);
  const { address } = useAccount();

  const { refetch } = useContractRead({
    ...DepositoryContract,
    functionName: "balances",
    args: [address],
    watch: true,
    onSuccess(data) {
      setDepositedBalance(formatEther(data));
    },
  });

  const handleWithdrawFieldChange = (e) => {
    const value = e.target.value;

    setWithdrawAmount(value);
  };

  const setMax = () => {
    setWithdrawAmount(depositedBalance);
  };

  // console.log(withdrawAmount);

  return (
    <div className="flex justify-center mt-14 md:mt-20">
      <div className="relative mb-10 sm:w-[450px] md:w-[525px] xl:w-[600px] overflow-hidden rounded-xl border border-gray-800 p-[1px] backdrop-blur-3xl fadeUp">
        <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#9E712C_0%,#E1AE57_50%,#9E712C_100%)]" />
        <div className=" h-full w-full  rounded-xl bg-secondary  text-sm font-medium text-gray-50 backdrop-blur-3xl">
          <h1 className="text-2xl text-center py-5 border-b-[0.25px] border-gray-800   text-gold">
            <span className="inline-flex animate-text-gradient bg-gradient-to-r from-[#FFD47F]  via-[#E1AE57] to-[#9E712C] bg-[200%_auto] bg-clip-text text-2xl text-transparent">
              PNGVN Withdraw
            </span>
          </h1>

          <div className=" px-10">
            <div className="flex items-center justify-end font-thin text-lg text-grey mt-10">
              {/* <span>Enter amount</span> */}
              <div>
                Deposited balance:{" "}
                <span className="font-normal text-light">
                  {depositedBalance}
                </span>
              </div>
            </div>

            <div className="pl-4 pr-2 mt-4 bg-[#1A1A21] border border-gray-800 rounded-lg flex items-center justify-between gap-4 ">
              <input
                onKeyDown={blockInvalidCharWithDecimal}
                type="number"
                spellCheck={false}
                className="w-full h-14 text-light text-xl outline-none bg-transparent  truncate placeholder:font-thin placeholder:text-gray-500 "
                placeholder="Enter amount"
                value={withdrawAmount}
                onChange={handleWithdrawFieldChange}
              />

              <Button onClick={setMax} className="bg-black text-light">
                Max
              </Button>
            </div>

            <WithdrawBtn
              withdrawAmount={withdrawAmount}
              refetch={refetch}
              setWithdrawAmount={setWithdrawAmount}
              fieldError={fieldError}
              setStakingActivity={setStakingActivity}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnstakeCard;
