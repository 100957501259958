import { Link, useLocation, useNavigate } from "react-router-dom";
import { sidebarNavLinks, adminAddresses } from "../../../constants";
import { ReactComponent as Disconnect } from "../../../assets/disconnect.svg";
import { ReactComponent as Documentation } from "../../../assets/documentation.svg";
import { ReactComponent as AdminSettings } from "../../../assets/admin-settings2.svg";
// import Logo2 from "../../../assets/logo.png";
import DesktopLogo from "../../../assets/PngvnLogoDesktop.png";
import MobileLogo from "../../../assets/PngvnLogoMobile2.png";
import { useDisconnect, useAccount } from "wagmi";

export function Sidebar() {
  const location = useLocation();
  const currentPath = location.pathname;

  const { disconnect } = useDisconnect();
  const { address } = useAccount();
  const navigate = useNavigate();

  const isAdmin = adminAddresses.includes(address);

  const handleDisconnect = () => {
    disconnect();
    navigate("/");
  };

  return (
    <div
      className="h-screen hidden md:w-[7rem] lg:w-[21rem] p-4 bg-secondary border-r-[0.25px] border-gray-900 md:flex flex-col 
    justify-between fixed exceeded:sticky left-0 top-0"
    >
      <div className="">
        <div className="mb-8 lg:mb-4 p-4">
          <img
            src={DesktopLogo}
            alt="gng"
            className="w-[120px] hidden lg:block"
          />
          <img src={MobileLogo} alt="gng" className="  lg:hidden" />
        </div>
        <div className="flex flex-col items-center  lg:items-stretch  lg:justify-start h-full gap-4">
          {/* =============== NAV LINKS =============== */}
          <div className="flex flex-col gap-4  lg:gap-2 lg:min-w-[240px] p-2 font-sans text-base font-normal text-grey">
            {sidebarNavLinks.map((link) => (
              <Link
                to={link.route}
                key={link.label}
                className={`flex items-center   lg:w-full p-4 lg:p-3 rounded-lg text-start leading-tight transition-all outline-none cursor-pointer ${
                  currentPath === link.route
                    ? "bg-gold bg-opacity-30 text-white"
                    : "hover:bg-gold hover:bg-opacity-30 hover:text-white group"
                } `}
              >
                <link.icon
                  className={`h-5 w-5 ${
                    currentPath === link.route
                      ? "text-gold"
                      : "group-hover:text-gold"
                  }`}
                />
                <span className="pl-4 lg:block hidden">{link.label}</span>
              </Link>
            ))}
            {isAdmin && (
              <Link
                to="/admin"
                key="admin"
                className="flex items-center   lg:w-full p-4 lg:p-3 rounded-lg text-start leading-tight transition-all outline-none cursor-pointer  hover:bg-gold hover:bg-opacity-30 hover:text-white group"
              >
                <AdminSettings className="h-5 w-5 group-hover:text-gold" />
                <span className="pl-4 lg:block hidden">Admin Panel</span>
              </Link>
            )}
          </div>

          {/* =============== SEPARATOR =============== */}
          <div className="px-2 lg:block hidden">
            <div className="h-[0.25px] bg-gray-900" />
          </div>

          <div className="absolute bottom-4 lg:static flex flex-col gap-2 lg:min-w-[240px] p-2 font-sans text-base font-normal text-grey">
            {/* =============== DOCUMENTATION =============== */}
            <a
              href="https://docs.pngvntoken.com/"
              target="_blank"
              rel="noreferrer"
              className="flex items-center cursor-pointer w-full p-3 rounded-lg text-start leading-tight transition-all hover:bg-gold hover:bg-opacity-30    hover:text-white  outline-none group"
            >
              <Documentation className="h-5 w-5  group-hover:text-gold" />
              <span className="pl-4 lg:block hidden">Docs</span>
            </a>

            {/* =============== DISCONNECT =============== */}
            <button
              onClick={handleDisconnect}
              className="flex items-center cursor-pointer w-full p-3 rounded-lg text-start leading-tight transition-all hover:bg-gold hover:bg-opacity-30    hover:text-white  outline-none group"
            >
              <Disconnect className="h-5 w-5  group-hover:text-gold" />
              <span className="pl-4 lg:block hidden">Disconnect</span>
            </button>
          </div>
        </div>
      </div>
      {/* <div className="hidden lg:flex justify-center ">
        <w3m-button size="sm" balance="hidden" />
      </div> */}
    </div>
  );
}
