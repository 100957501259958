import DepositoryAbi from "./abis/DepositoryAbi.json";

const DepositoryAddress = "0x155e1791ee33fdc273e068f559e614a3de2858ee";
// TODO add final mainnet Depository: 0x155e1791ee33fdc273e068f559e614a3de2858ee

// 0x3f7fa2aea62810d48871f248b68cc3e82606507e added dividendRoundCreated event
// 0x350d0ad21B66C8766C1830ac3bF51E6BdF12F4Dd most of work done here all is well
// 0x32F8A8C2156F732C95032c0cFafc87b1CfFA6DEA after the one below had many deposits and withdrawals
// 0xa508C371a56b14fB0Fbf8D20A42a0cB8C88975c4 old contract did 7 deposits and some withdrawals but needed add timestamp to txns

export const DepositoryContract = {
  address: DepositoryAddress,
  abi: DepositoryAbi,
};
