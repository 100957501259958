import React, { useState, useEffect } from "react";
import EmergencyWithdrawBtn from "./EmergencyWithdrawBtn";
import PauseClaimBtn from "./PauseClaimBtn";
import SetRootBtn from "./SetRootBtn";
import { useAccount } from "wagmi";
import { DepositoryContract } from "../../contracts/Depository";
import { ethers } from "ethers";
import { formatEther } from "viem";
import { spliceRoot } from "../../lib/utils";
import Skeleton from "react-loading-skeleton";
import FinalizeRoundBtn from "./FinalizeRoundBtn";

const EmergencySettings = () => {
  const [loaded, setLoaded] = useState(false);
  const [root, setRoot] = useState("");
  const [contractBal, setContractBal] = useState("0");
  const [contractRoot, setContractRoot] = useState("");
  const [contractClaimStatus, setContractClaimStatus] = useState(false);

  const { isConnected } = useAccount();

  const handleRootChange = (e) => {
    const newVal = e.target.value;
    setRoot(newVal);
  };

  useEffect(() => {
    setLoaded(false);
    const fetchData = async () => {
      if (isConnected) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();

        const depositoryContract = new ethers.Contract(
          DepositoryContract.address,
          DepositoryContract.abi,
          signer
        );

        const rootHash = await depositoryContract.root();
        const currentClaimStatus = await depositoryContract.claimStatus();
        const contractBalance = await provider.getBalance(
          DepositoryContract.address
        );

        if (currentClaimStatus === 0) {
          setContractClaimStatus(false);
        } else {
          setContractClaimStatus(true);
        }

        setContractBal(
          Number(formatEther(contractBalance)).toFixed(2).toString()
        );
        setContractRoot(spliceRoot(rootHash));
      }

      setLoaded(true);
    };
    fetchData();
  }, [isConnected]);

  //   useContractReads({
  //     contracts: [
  //       {
  //         ...DepositoryContract,
  //         functionName: "getAlive",
  //       },
  //       {
  //         ...DepositoryContract,
  //         functionName: "getBoredom",
  //       },
  //     ],
  //   });

  return (
    <div className=" pt-24">
      <div className="flex flex-col gap-4">
        <h1 className="text-gold text-4xl font-semibold">Settings</h1>
        <p className="text-darkgray font-normal">
          This page is dedicated to emergency settings for the platform.
          Comprising of an emergency withdrawal, claim status pause, a manual
          root hash override and a finalize dividend round function.
        </p>
      </div>

      {loaded ? (
        <div>
          <div className="mt-20 bg-secondary rounded-xl px-10 py-6">
            <div className="flex justify-between">
              <div className="flex flex-col">
                <span className="text-darkgray">Contract Balance:</span>
                <span className="text-light text-4xl pt-4 font-semibold">
                  {contractBal} ETH
                </span>
              </div>

              <div className="flex flex-col">
                <span className="text-darkgray">Claim Status:</span>
                <span className="text-light text-4xl pt-4 font-semibold">
                  {contractClaimStatus ? (
                    <span className="text-green-600">Active</span>
                  ) : (
                    <span>Paused</span>
                  )}
                </span>
              </div>

              <div className="flex flex-col">
                <span className="text-darkgray">Current Root:</span>
                <span className="text-light text-4xl pt-4 font-semibold">
                  {contractRoot}
                </span>
              </div>
            </div>

            <div className="flex justify-between items-center  py-20">
              <div className="flex flex-col gap-2">
                <span className="text-light text-2xl font-semibold">
                  Finalize Dividend Round
                </span>
                <p className="text-darkgray font-normal">
                  Finalize current claim round, and redeem all unclaimed rewards
                </p>
              </div>

              <FinalizeRoundBtn
                setContractClaimStatus={setContractClaimStatus}
                setContractBal={setContractBal}
              />
            </div>

            <div className="flex justify-between items-center  pb-20">
              <div className="flex flex-col gap-2">
                <span className="text-light text-2xl font-semibold">
                  Withdraw Contract Balance
                </span>
                <p className="text-darkgray font-normal">
                  Withdraw the smart contracts Ethereum balance to the admin
                  wallet
                </p>
              </div>

              <EmergencyWithdrawBtn setContractBal={setContractBal} />
            </div>

            <div className="flex justify-between items-center pb-20">
              <div className="flex flex-col gap-2">
                <span className="text-light text-2xl font-semibold">
                  Pause Claim Status
                </span>
                <p className="text-darkgray font-normal">
                  Pause any and all claiming transactions being submitted to the
                  smart contract
                </p>
              </div>

              <PauseClaimBtn setContractClaimStatus={setContractClaimStatus} />
            </div>

            <div className="">
              <div className="flex flex-col gap-2">
                <span className="text-light text-2xl font-semibold">
                  Merkle Root Override
                </span>
                <p className="text-darkgray font-normal">
                  Manual override to set the root hash of the Merkle tree
                  (caution: must match database merkle data)
                </p>
              </div>

              <div className="flex justify-between items-center mt-6">
                <div className="pl-4 pr-2  bg-[#1A1A21] border border-gray-800 rounded-lg flex items-center justify-between gap-4 ">
                  <input
                    // onKeyDown={blockInvalidCharWithDecimal}
                    type="text"
                    spellCheck={false}
                    className="w-[600px] h-10 text-light text-xl outline-none bg-transparent  truncate placeholder:font-thin placeholder:text-gray-500 "
                    value={root}
                    onChange={handleRootChange}
                    placeholder="Enter root (bytes32)"
                  />
                </div>

                <SetRootBtn root={root} setContractRoot={setContractRoot} />
              </div>
            </div>
          </div>
          <div className="w-full h-[1px] mt-10" />
        </div>
      ) : (
        <div className="mt-20 bg-secondary rounded-xl px-10 py-6">
          <div className="flex justify-between">
            <div className="flex flex-col">
              <Skeleton height={15} width={100} />
              <Skeleton height={45} width={175} className="mt-2" />
            </div>

            <div className="flex flex-col">
              <Skeleton height={15} width={100} />
              <Skeleton height={45} width={175} className="mt-2" />
            </div>

            <div className="flex flex-col">
              <Skeleton height={15} width={100} />
              <Skeleton height={45} width={175} className="mt-2" />
            </div>
          </div>

          <div className="flex justify-between items-center  py-20">
            <div className="flex flex-col gap-2">
              <Skeleton height={25} width={300} />
              <Skeleton height={15} width={470} className="mt-2" />
            </div>

            <Skeleton height={40} width={120} />
          </div>

          <div className="flex justify-between items-center  pb-20">
            <div className="flex flex-col gap-2">
              <Skeleton height={25} width={300} />
              <Skeleton height={15} width={470} className="mt-2" />
            </div>

            <Skeleton height={40} width={120} />
          </div>

          <div className="flex justify-between items-center ">
            <div className="flex flex-col gap-2">
              <Skeleton height={25} width={300} />
              <Skeleton height={15} width={470} className="mt-2" />
            </div>

            <Skeleton height={40} width={120} />
          </div>
        </div>
      )}
    </div>
  );
};

export default EmergencySettings;
