import React, { useEffect, useState, useContext } from "react";
import { getTokenClaimMerkleData } from "../firebase/FirebaseConfig";
import { ethers } from "ethers";
import {
  // getLeaf,
  // generateMerkleProof,
  // generateMerkleRoot,
  generateMerkleProofConvertingToWeiValues,
  getLeafConvertingToWeiValues,
} from "../lib/utils";
import {
  useAccount,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import { PngvnTokenClaimContract } from "../contracts/PngvnClaim";
import { DappContext } from "../context/Dapp.context";
import { addCommasToIntegerString } from "../lib/utils";
import { Button } from "@material-tailwind/react";
import { toast } from "sonner";

const ClaimTokens = () => {
  const [loaded, setLoaded] = useState(false);
  const [isClaimLive, setIsClaimLive] = useState(false);
  const [shareAlreadyClaimed, setShareAlreadyClaimed] = useState(false);
  const [amountToClaim, setAmountToClaim] = useState("0");
  const [proof, setProof] = useState("");
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [prepareError, setPrepareError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const { address } = useAccount();
  const { wrongNetwork } = useContext(DappContext);

  useEffect(() => {
    setLoaded(false);

    const fetchData = async () => {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      const tokenClaimContract = new ethers.Contract(
        PngvnTokenClaimContract.address,
        PngvnTokenClaimContract.abi,
        signer
      );

      const claimLive = await tokenClaimContract.claimStatus();

      if (claimLive) {
        setIsClaimLive(true);
      } else setIsClaimLive(false);

      const claimShareData = await getTokenClaimMerkleData();

      const userClaimShare = claimShareData.find(
        (share) => share.id === address
      );

      // console.log(userClaimShare);

      if (!userClaimShare) {
        setShareAlreadyClaimed(true);
        setAmountToClaim("0");
        setLoaded(true);
        return;
      }

      const isLeafClaimed = await tokenClaimContract.leafClaimed(
        getLeafConvertingToWeiValues(userClaimShare.id, userClaimShare.share)
      );

      if (isLeafClaimed) {
        setShareAlreadyClaimed(true);
        setAmountToClaim("0");
      } else {
        setShareAlreadyClaimed(false);
        setAmountToClaim(
          ethers.utils.parseEther(userClaimShare.share).toString()
        );
        setProof(
          generateMerkleProofConvertingToWeiValues(
            claimShareData,
            userClaimShare.id,
            userClaimShare.share
          )
        );
      }

      setLoaded(true);
    };

    if (!wrongNetwork) {
      fetchData();
    }
  }, [address, wrongNetwork]);

  // ***************************************************************************************** CONTRACT INTERACTION LOGIC
  const { config } = usePrepareContractWrite({
    ...PngvnTokenClaimContract,
    functionName: "claim",
    args: [amountToClaim || "0", proof],
    onSuccess(data) {
      setPrepareError(false);
      setErrorMsg("");
    },
    onError(error) {
      setPrepareError(true);
      setErrorMsg(error.shortMessage);
      console.log(error);
    },
  });

  const { write: ClaimShare, data: txnData } = useContractWrite({
    ...config,
    onSuccess(data) {
      setIsBtnLoading(true);
    },
    onError(error) {
      setIsBtnLoading(false);
      toast.error(error.shortMessage);
    },
  });

  useWaitForTransaction({
    hash: txnData?.hash,
    onSuccess(data) {
      toast.success("Tokens successfully claimed");
      setAmountToClaim("0");
      setIsBtnLoading(false);
      setShareAlreadyClaimed(true);
    },
    onError(error) {
      setIsBtnLoading(false);
      toast.error(error.shortMessage);
    },
  });

  const claimHandler = () => {
    if (prepareError) {
      toast.error(errorMsg);
      return;
    }

    setIsBtnLoading(true);
    ClaimShare?.();
  };

  return (
    <div className="h-claimTokensPageHeight relative">
      <div className="px-4 md:px-14 py-10 h-full">
        <div className="flex flex-col gap-4">
          <h1 className="text-light text-4xl font-semibold">Tokens Claim</h1>
          <p className="text-darkgray font-normal">
            Claim your PNGVN tokens safely and securly on our tokens claim page
            so that you are able to easily participate and contribute as a
            community member. Be sure to follow us for the latest news on how to
            gain maximum benefits as a PNGVN token hodler.
          </p>
        </div>

        {loaded ? (
          <div className="flex flex-col justify-center pt-24 md:pt-44 overflow-hidden">
            <div className="text-center text-4xl md:text-5xl font-bold flex items-center gap-4 justify-center">
              {addCommasToIntegerString(
                ethers.utils.formatEther(amountToClaim || "0").toString()
              )}
              <p className="">$PNGVN</p>
            </div>

            {!shareAlreadyClaimed && (
              <div className="flex justify-center pt-10">
                <Button
                  onClick={claimHandler}
                  disabled={isBtnLoading}
                  className="bg-gold w-[200px]"
                >
                  {isBtnLoading ? "CLAIMING TOKENS..." : "CLAIM TOKENS"}
                </Button>
              </div>
            )}

            {/* <div className="absolute bottom-0 right-0">FUCK</div> */}
          </div>
        ) : (
          <div className="w-full h-[520px] flex justify-center items-center">
            <div class="loader"></div>
          </div>
        )}
      </div>

      {loaded && (
        <div className="absolute bottom-10 right-14 flex items-center gap-4">
          <div className="flex justify-center items-center">
            <div
              className={`heartbeat ${
                isClaimLive ? "bg-green-700" : "bg-red-700"
              } `}
            ></div>
            <div
              className={`${isClaimLive ? "dot" : "inactiveDot"} absolute`}
            ></div>
          </div>
          <p
            className={`${
              isClaimLive ? "text-green-700" : "text-red-700"
            } font-normal`}
          >
            {isClaimLive ? "Claim live" : "Claim inactive"}
          </p>
        </div>
      )}
    </div>
  );
};

export default ClaimTokens;
