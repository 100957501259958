import React, { useState, useEffect, useContext } from "react";
import { ReactComponent as ThreeDots } from "../assets/three-dots.svg";
import { ReactComponent as Staking } from "../assets/staking.svg";
import { ReactComponent as RevenueShare } from "../assets/revenue-share.svg";
import TransactionTable from "../components/shared/TransactionTable";
import {
  Popover,
  PopoverHandler,
  PopoverContent,
  List,
  // ListItem,
  // ListItemPrefix,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
// import { claimActivity, stakingActivity } from "../constants";
import {
  getUserDepositoryEvents,
  getUserShareClaimEvents,
} from "../hooks/FetchContractEvents";
import { useAccount } from "wagmi";
import { DappContext } from "../context/Dapp.context";

const Home = () => {
  const [stakingActivity, setStakingActivity] = useState([]);
  const [claimActivity, setClaimActivity] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const { address } = useAccount();

  const { wrongNetwork } = useContext(DappContext);

  // console.log(wrongNetwork);
  // w

  useEffect(() => {
    setLoaded(false);

    //
    const fetchData = async () => {
      let stakingRes = await getUserDepositoryEvents(address);

      let claimRes = await getUserShareClaimEvents(address);

      if (stakingRes.length > 3) {
        stakingRes = stakingRes.slice(0, 3);
      }

      if (claimRes.length > 3) {
        claimRes = claimRes.slice(0, 3);
      }

      setStakingActivity(stakingRes);
      setClaimActivity(claimRes);
      setLoaded(true);
    };

    if (!wrongNetwork) {
      fetchData();
    }
  }, [address, wrongNetwork]);

  return (
    <div className=" md:pb-24  mt-14">
      <div className="text-light flex justify-between items-center px-4 md:px-14 mb-6">
        <span className="text-2xl font-semibold">Your Recent Activity</span>
        <Popover placement="bottom-end">
          <PopoverHandler>
            <ThreeDots className="h-5 w-5 cursor-pointer" />
          </PopoverHandler>
          <PopoverContent className=" mt-1  bg-secondary border-secondary shadow-none ">
            <List className="p-0">
              <Link
                to="/revenue-share"
                className="flex items-center   lg:w-full p-4 lg:p-3 rounded-lg text-start leading-tight transition-all outline-none cursor-pointer hover:bg-gold hover:bg-opacity-30  group"
              >
                <RevenueShare className="w-[16px] h-[16px] text-grey group-hover:text-gold" />
                <span className="pl-4 text-grey group-hover:text-white">
                  Explore All Claim Activity
                </span>
              </Link>

              <Link
                to="/depository"
                className="flex items-center   lg:w-full p-4 lg:p-3 rounded-lg text-start leading-tight transition-all outline-none cursor-pointer hover:bg-gold hover:bg-opacity-30  group"
              >
                <Staking className="w-[16px] h-[16px] text-grey group-hover:text-gold" />
                <span className="pl-4 text-grey group-hover:text-white">
                  Explore All Depository Activity
                </span>
              </Link>
            </List>
          </PopoverContent>
        </Popover>
      </div>

      <TransactionTable
        activity={claimActivity}
        loaded={loaded}
        title="Claim activity"
      />

      <TransactionTable
        activity={stakingActivity}
        loaded={loaded}
        title="Depository activity"
      />

      {/* <div className="text-light flex justify-between items-center px-14 my-10">
        <span className="text-2xl font-semibold">Your Balance Overview</span>
        <Popover placement="bottom-end">
          <PopoverHandler>
            <ThreeDots className="h-5 w-5 cursor-pointer" />
          </PopoverHandler>
          <PopoverContent className=" mt-1  bg-secondary border-secondary shadow-none ">
            <List className="p-0">
              <Link
                to="/revenue-share"
                className="flex items-center   lg:w-full p-4 lg:p-3 rounded-lg text-start leading-tight transition-all outline-none cursor-pointer hover:bg-gold hover:bg-opacity-30  group"
              >
                <RevenueShare className="w-[16px] h-[16px] text-grey group-hover:text-gold" />
                <span className="pl-4 text-grey group-hover:text-white">
                  Explore All Claim Activity
                </span>
              </Link>

              <Link
                to="/depository"
                className="flex items-center   lg:w-full p-4 lg:p-3 rounded-lg text-start leading-tight transition-all outline-none cursor-pointer hover:bg-gold hover:bg-opacity-30  group"
              >
                <Staking className="w-[16px] h-[16px] text-grey group-hover:text-gold" />
                <span className="pl-4 text-grey group-hover:text-white">
                  Explore All Depository Activity
                </span>
              </Link>
            </List>
          </PopoverContent>
        </Popover>
      </div>

      <div className="flex justify-between px-14">
        <div className="flex flex-col gap-6">
          <div className="h-[100px] w-[400px] border border-gold  rounded-xl "></div>
          <div className="h-[100px] w-[400px] border border-gold  rounded-xl "></div>
        </div>

        <div className="flex flex-col gap-6">
          <div className="h-[100px] w-[400px] border border-gold  rounded-xl "></div>
          <div className="h-[100px] w-[400px] border border-gold  rounded-xl "></div>
        </div>
      </div> */}
    </div>
  );
};

export default Home;
