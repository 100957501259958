import { Bars3Icon } from "@heroicons/react/24/solid";
import { IconButton } from "@material-tailwind/react";
import React from "react";

const HamburgerMenu = ({ setIsDrawerOpen }) => {
  const toggleDrawer = () => {
    setIsDrawerOpen((prevIsDrawerOpen) => !prevIsDrawerOpen);
  };
  return (
    <div className="md:hidden">
      <IconButton
        variant="text"
        size="lg"
        onClick={toggleDrawer}
        className="text-white"
      >
        <Bars3Icon className="h-10 w-10 stroke-2" />
      </IconButton>
    </div>
  );
};

export default HamburgerMenu;
