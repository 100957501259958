import React from "react";
import { ReactComponent as Eth } from "../../assets/eth-symbol.svg";
import { ReactComponent as Token } from "../../assets/token.svg";
import { ReactComponent as RevenueShare } from "../../assets/revenue-share.svg";
import { ReactComponent as Staking } from "../../assets/staking.svg";
import { formatNumber, getElapsedTime, spliceHash } from "../../lib/utils";

const TransactionCard = ({ event, amount, timestamp, hash, isLastElement }) => {
  return (
    <div className={isLastElement ? "" : "border-b-[0.25px] border-gray-900"}>
      <div className="py-6 px-4 md:px-14 flex justify-between items-center">
        {/* Icon + Type/date */}
        <div className="flex  items-center gap-4 md:w-[33%]">
          <div className="h-[55px] w-[55px] bg-gold bg-opacity-30  flex justify-center items-center rounded-xl">
            {event === "HolderShareClaimed" ? (
              <RevenueShare className="h-6 w-6  text-gold" />
            ) : (
              <Staking className="h-6 w-6  text-gold" />
            )}
          </div>
          <div className="flex flex-col gap-1 text-light font-medium">
            <span>
              {event === "HolderShareClaimed" ? "Share claim" : event}
            </span>
            <span className="text-grey font-thin">
              {getElapsedTime(timestamp)}
            </span>
          </div>
        </div>

        {/* Amount + Currency */}
        <div className="hidden md:flex flex-col text-light font-medium w-[33%]">
          <span>{event === "HolderShareClaimed" ? "Eth" : "PNGVN"}</span>
          <div className="flex items-center gap-2">
            <span className="text-grey font-normal">
              {event === "HolderShareClaimed"
                ? amount
                : formatNumber(Number(amount))}
            </span>

            {event === "HolderShareClaimed" ? (
              <Eth className="h-4 w-4 text-[#7B7B7B]" />
            ) : (
              <Token className="h-4  w-4 text-[#7B7B7B]" />
            )}
          </div>
        </div>

        {/* Txn + Hash */}
        <a
          // href={`https://sepolia.etherscan.io/tx/${hash}`}
          href={`https://etherscan.io/tx/${hash}`}
          className="flex flex-col gap-1 cursor-pointer group"
          target="_blank"
          rel="noreferrer"
        >
          <span className="text-purpleAccent font-medium group-hover:opacity-70 transition-all duration-300">
            View transaction
          </span>
          <span className="text-grey font-thin">Hash #{spliceHash(hash)}</span>
        </a>
      </div>
    </div>
  );
};

export default TransactionCard;
