import React from "react";
import { footerLinks } from "../../constants";

const Footer = () => {
  return (
    <div className="flex items-center justify-between px-4 md:px-14 text-light mt-10 pb-6">
      <div className="text-darkgray">
        ©2024 <span className="text-gold pl-1">PNGVN</span>
      </div>
      <span className="xl:hidden text-darkgray">ALL RIGHTS RESERVED</span>
      <div className="hidden xl:flex gap-6 text-darkgray">
        {footerLinks.map((link) => (
          <a
            key={link.id}
            href={link.link}
            target="_blank"
            rel="noreferrer"
            className="hover:text-purpleAccent cursor-pointer"
          >
            {link.label}
          </a>
        ))}
      </div>
    </div>
  );
};

export default Footer;
